import { PrivyProvider } from "@privy-io/react-auth";
import { WagmiProvider as WagmiWrapper, createConfig } from "@privy-io/wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { http } from "wagmi";
import { baseSepolia, mainnet } from "wagmi/chains";

type Props = {
  children: ReactNode;
};

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

export const config = createConfig({
  chains: [baseSepolia],
  transports: {
    [baseSepolia.id]: http(process.env.NEXT_PUBLIC_QUICKNODE_URL),
    [mainnet.id]: http(process.env.NEXT_PUBLIC_MAINNET_URL),
  },
});

const queryClient = new QueryClient();

export default function ChainProvider({ children }: Props) {
  return (
    <PrivyProvider appId="cltq3e8wg0bsz2krrnqnrngf5">
      <QueryClientProvider client={queryClient}>
        <WagmiWrapper config={config}>{children}</WagmiWrapper>
      </QueryClientProvider>
    </PrivyProvider>
  );
}
