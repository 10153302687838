import AppState from "./AppState";

function AppWrapper({ children }: { children: JSX.Element }) {
  return (
    <AppState>
      {children}
    </AppState>
  );
}

export default AppWrapper;
