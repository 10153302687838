import posthog from "posthog-js";
import { ReactNode, ReactElement, useCallback, useEffect } from "react";
import { createGenericContext } from "../../utilities/context";
import { handleCaughtError } from "../../utilities";
import { useAuthContext } from "./AuthProvider";
import { useRouter } from "next/router";

export type Attributes = {
  bunchId?: string;
  creatorId?: string;
  creatorUsername?: string;
  memberId?: string;
  name?: string;
  userId?: string;
  username?: string;
  messageId?: string;
  messageAuthorId?: string;
  messageAuthorUsername?: string;
  path?: string;
  walletAddress?: string;
  source?: string | string[];
  share_source?: string | string[];
};

export type AnalyticsContext = {
  pushTrackEvent: (name: string, attributes?: Attributes) => void;
};

export type AnalyticsProviderProps = {
  apiKey: string;
  userId: string;
  children: ReactElement;
};

type UseAnalytics = {
  pushTrackEvent: (name: string, attributes?: Attributes) => void;
};

const [useAnalyticsContext, AnalyticsContextProvider] = createGenericContext<UseAnalytics>();

const useTracking = (): UseAnalytics => {
  const { currentUser } = useAuthContext();
  const posthogApiKey = process.env.NEXT_PUBLIC_POSTHOG_KEY || "";

  const init = useCallback(async () => {
    try {
      posthog.init(posthogApiKey, {
        api_host: "/ingest",
        ui_host: "https://us.i.posthog.com",
      });
      if (currentUser && currentUser.userId)
        posthog.identify(currentUser.userId, {
          email: currentUser.email,
          username: currentUser.username,
          phone: currentUser.phone,
        });
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [posthogApiKey, currentUser]);

  const pushTrackEvent = useCallback((name: string, attributes?: Attributes) => {
    posthog?.capture(name, { ...attributes, source: "Web" });
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    pushTrackEvent,
  };
};

interface Props {
  children: ReactNode;
}

const AnalyticsProvider = ({ children }: Props) => {
  const tracking = useTracking();
  const router = useRouter();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    tracking.pushTrackEvent("Page visit", { path: router.asPath, userId: currentUser?.userId });
  }, [router, currentUser, tracking]);

  return <AnalyticsContextProvider value={tracking}>{children}</AnalyticsContextProvider>;
};

export { useAnalyticsContext, AnalyticsProvider };
