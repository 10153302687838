import "../styles/reset.css";
import "../styles/globals.css";
import "../styles/nprogress.css";
import "../styles/overrides.css";
import "react-loading-skeleton/dist/skeleton.css";
import type { AppProps } from "next/app";
import AppWrapper from "../components/shared/layout/AppLayout/AppWrapper";
import CustomToastContainer from "../components/shared/toasts";
import NProgress from "nprogress";
import { useEffect } from "react";
import { useRouter } from "next/router";

NProgress.configure({ showSpinner: false });

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // Hide splash screen when we are server side
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("globalLoader");
      if (loader) loader.style.display = "none";
    }
  }, []);

  // Handle routing progress indicator
  useEffect(() => {
    const handleChangeStart = () => NProgress.start();
    const handleChangeComplete = () => NProgress.done();

    router.events.on("routeChangeStart", handleChangeStart);
    router.events.on("routeChangeComplete", handleChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleChangeStart);
      router.events.off("routeChangeComplete", handleChangeComplete);
    };
  }, [router.events]);

  return (
    <AppWrapper>
      <CustomToastContainer />
      <Component {...pageProps} />
    </AppWrapper>
  );
}
