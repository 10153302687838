import { ethers } from "ethers";

type Address = `0x${string}`;

type Wallet = {
  address: Address;
  mnemonic: string;
};

const WALLET = "non-custodial-bunches-wallet-";

/**
 * Generates and returns a random HD Wallet
 *
 * @returns address; mnemonicString:  Ethereum Address string; 12 word mnemonic phrase as a string
 */
function generateNewWallet(): { address: Address; mnemonic: string } {
  const wallet = ethers.Wallet.createRandom();
  const address = wallet.address as Address;
  const mnemonic = wallet.mnemonic.phrase;
  return { address, mnemonic };
}

/**
 * Sets HD Wallet to Shared Preferences
 * @param wallet: Wallet, address and mnemonic string
 */
async function setWallet(wallet: Wallet, userId: string): Promise<void> {
  localStorage.setItem(WALLET + userId, JSON.stringify(wallet));
}

/**
 * Gets HD Wallet from Shared Preferences
 * @returns wallet: Wallet , address and mnemonic string
 */
export async function getWallet(userId: string) {
  const walletJson = await JSON.parse(localStorage.getItem(WALLET + userId) ?? "null");
  if (!walletJson) {
    console.error(`key '${WALLET + userId}' is undefined in Shared Preferences`);
    return undefined;
  }
  return walletJson as Wallet;
}

/**
 * Return whether there is a wallet stored in Shared Preferences
 * @returns true if wallet exists in Shared Preferences
 */
export async function hasWallet(userId: string): Promise<boolean> {
  const walletJson = await localStorage.get({ key: WALLET + userId });
  return !!walletJson.value;
}

/**
 * Generates a random HD Wallet and sets in Shared Preferences
 * @returns wallet: Wallet, address and mnemonic string
 */
export async function generateAndSetWallet(userId: string): Promise<Wallet> {
  const wallet = generateNewWallet();
  await setWallet(wallet, userId);
  return wallet;
}
