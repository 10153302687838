import { gql, TypedDocumentNode } from "@apollo/client";
import { MESSAGE_DETAILS } from "../fragments";
import { CreatePollMutation, CreatePollMutationVariables } from "../types";

export const CREATE_POLL: TypedDocumentNode<CreatePollMutation, CreatePollMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation createPoll(
    $bunchId: ID!
    $clientMessageId: ID!
    $clientPollId: ID!
    $poll: PollInput!
    $parentMessageId: ID
  ) {
    createPoll(
      bunchId: $bunchId
      clientMessageId: $clientMessageId
      clientPollId: $clientPollId
      poll: $poll
      parentMessageId: $parentMessageId
    ) {
      ...MessageDetails
    }
  }
`;
