import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import {
  BoostMessageMutation,
  BoostMessageMutationVariables
} from "../types";

export const BOOST_MESSAGE: TypedDocumentNode<BoostMessageMutation, BoostMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation boostMessage($messageId: ID!, $userId: ID!) {
    boostMessage(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;
