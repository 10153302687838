import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MEMBER_DETAILS, USER_DETAILS } from "../fragments";
import {
  AcceptFriendRequestMutation,
  AcceptFriendRequestMutationVariables,
  CancelFriendRequestMutation,
  CancelFriendRequestMutationVariables,
  DenyFriendRequestMutation,
  DenyFriendRequestMutationVariables,
  GetFriendCountQuery,
  GetFriendCountQueryVariables,
  GetFriendsForUserQuery,
  GetFriendsForUserQueryVariables,
  HasFriendRequestedQuery,
  HasFriendRequestedQueryVariables,
  IsFriendsQuery,
  IsFriendsQueryVariables,
  SendFriendRequestMutation,
  SendFriendRequestMutationVariables,
  UnfriendUserMutation,
  UnfriendUserMutationVariables,
} from "../types";

export const GET_FRIEND_REQUESTS = gql`
  ${USER_DETAILS}
  query getFriendRequests($userId: ID!) {
    getFriendRequests(userId: $userId) {
      summary
      user {
        ...UserDetails
      }
    }
  }
`;

export const ACCEPT_FRIEND_REQUEST: TypedDocumentNode<
  AcceptFriendRequestMutation,
  AcceptFriendRequestMutationVariables
> = gql`
  mutation acceptFriendRequest($callerId: ID!, $subjectId: ID!) {
    acceptFriendRequest(callerId: $callerId, subjectId: $subjectId)
  }
`;

export const CANCEL_FRIEND_REQUEST: TypedDocumentNode<
  CancelFriendRequestMutation,
  CancelFriendRequestMutationVariables
> = gql`
  mutation cancelFriendRequest($callerId: ID!, $subjectId: ID!) {
    cancelFriendRequest(callerId: $callerId, subjectId: $subjectId)
  }
`;

export const DENY_FRIEND_REQUEST: TypedDocumentNode<
  DenyFriendRequestMutation,
  DenyFriendRequestMutationVariables
> = gql`
  mutation denyFriendRequest($callerId: ID!, $subjectId: ID!) {
    denyFriendRequest(callerId: $callerId, subjectId: $subjectId)
  }
`;

export const SEND_FRIEND_REQUEST: TypedDocumentNode<
  SendFriendRequestMutation,
  SendFriendRequestMutationVariables
> = gql`
  mutation sendFriendRequest($callerId: ID!, $subjectId: ID!) {
    sendFriendRequest(callerId: $callerId, subjectId: $subjectId)
  }
`;

export const UNFRIEND_USER: TypedDocumentNode<UnfriendUserMutation, UnfriendUserMutationVariables> = gql`
  ${MEMBER_DETAILS}
  mutation unfriendUser($callerId: ID!, $subjectId: ID!) {
    unfriendUser(callerId: $callerId, subjectId: $subjectId) {
      ...MemberDetails
    }
  }
`;

export const GET_FRIEND_COUNT: TypedDocumentNode<GetFriendCountQuery, GetFriendCountQueryVariables> = gql`
  query getFriendCount($userId: ID!) {
    getFriendCount(userId: $userId)
  }
`;

export const GET_FRIENDS_FOR_USER: TypedDocumentNode<GetFriendsForUserQuery, GetFriendsForUserQueryVariables> = gql`
  ${MEMBER_DETAILS}
  query getFriendsForUser($callerId: ID!, $subjectId: ID, $count: Int) {
    getFriendsForUser(callerId: $callerId, subjectId: $subjectId, count: $count) {
      ...MemberDetails
      friendStatus
    }
  }
`;

export const HAS_FRIEND_REQUESTED: TypedDocumentNode<HasFriendRequestedQuery, HasFriendRequestedQueryVariables> = gql`
  query hasFriendRequested($userId: ID!, $subjectId: ID!) {
    hasFriendRequested(userId: $userId, subjectId: $subjectId)
  }
`;

export const IS_FRIENDS: TypedDocumentNode<IsFriendsQuery, IsFriendsQueryVariables> = gql`
  query isFriends($userId: ID!, $subjectId: ID!) {
    isFriends(userId: $userId, subjectId: $subjectId)
  }
`;
