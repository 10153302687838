import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { USER_DETAILS } from "../fragments";
import { UpdateUserMutation, UpdateUserMutationVariables } from "../types";

export const UPDATE_USER: TypedDocumentNode<UpdateUserMutation, UpdateUserMutationVariables> = gql`
  ${USER_DETAILS}
  mutation updateUser(
    $userId: ID!
    $username: String
    $phone: String
    $about: String
    $location: String
    $url: String
    $profilePictureUrl: String
    $coverPhotoUrl: String
    $instagram: String
    $pinterest: String
    $soundcloud: String
    $spotify: String
    $twitter: String
    $youtube: String
  ) {
    updateUser(
      userId: $userId
      username: $username
      phone: $phone
      about: $about
      location: $location
      url: $url
      profilePictureUrl: $profilePictureUrl
      coverPhotoUrl: $coverPhotoUrl
      instagram: $instagram
      pinterest: $pinterest
      soundcloud: $soundcloud
      spotify: $spotify
      twitter: $twitter
      youtube: $youtube
    ) {
      ...UserDetails
    }
  }
`;
