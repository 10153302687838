import { gql, TypedDocumentNode } from "@apollo/client";
import { RANKING_DETAILS_FRAGMENT } from "../fragments";
import { GetRankingDetailsQuery, GetRankingDetailsQueryVariables } from "../types";

export const GET_RANKING_DETAILS: TypedDocumentNode<GetRankingDetailsQuery, GetRankingDetailsQueryVariables> = gql`
  ${RANKING_DETAILS_FRAGMENT}
  query getRankingDetails($clientRankingId: ID, $rankingId: ID) {
    getRankingDetails(clientRankingId: $clientRankingId, rankingId: $rankingId) {
      ...RankingDetails
    }
  }
`;
