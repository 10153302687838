import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MEMBER_DETAILS } from "../fragments";
import {
  BlockUserMutation,
  BlockUserMutationVariables,
  FetchBlockedUsersQuery,
  FetchBlockedUsersQueryVariables,
  UnblockUserMutation,
  UnblockUserMutationVariables,
} from "../types";

export const BLOCK_USER: TypedDocumentNode<BlockUserMutation, BlockUserMutationVariables> = gql`
  ${MEMBER_DETAILS}
  mutation blockUser($userId: ID!, $userIdToBlock: ID!) {
    blockUser(userId: $userId, userIdToBlock: $userIdToBlock) {
      ...MemberDetails
    }
  }
`;

export const UNBLOCK_USER: TypedDocumentNode<UnblockUserMutation, UnblockUserMutationVariables> = gql`
  ${MEMBER_DETAILS}
  mutation unblockUser($userId: ID!, $userIdToUnblock: ID!) {
    unblockUser(userId: $userId, userIdToUnblock: $userIdToUnblock) {
      ...MemberDetails
    }
  }
`;

export const FETCH_BLOCKED_USERS: TypedDocumentNode<FetchBlockedUsersQuery, FetchBlockedUsersQueryVariables> = gql`
  ${MEMBER_DETAILS}
  query fetchBlockedUsers($userId: ID!) {
    fetchBlockedUsers(userId: $userId) {
      ...MemberDetails
    }
  }
`;
