import Image from "next/image";
import { ReactNode } from "react";
import { Slide, ToastContainer, toast } from 'react-toastify';
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.min.css';

type TypeOptions = "info" | "success" | "warning" | "error";

type ToastOptions = {
  type?: TypeOptions;
  autoClose?: number;
};

const BunchesToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    display: flex;
    align-items: center;
    border-radius: "9999px";
    padding: 0.1rem 0.5rem;
    width: 95vw;
  }
  .Toastify__toast {
    background-color: var(--color-primary);
    color: var(--color-milk);
    min-height: auto;
    max-height: auto;
  }
  .Toastify__toast--error {
    background-color: var(--color-milk);
    color: var(--color-gray-1);
  }
  .Toastify__toast-body {
    font-family: "Napa";
    font-size: 0.9em;
  }
  .Toastify__progress-bar {
  }
`;

const ToastContent = styled.div``;

const CloseContainer = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
`;

const CloseButton = ({ closeToast }) => {
  return (
    <CloseContainer onClick={closeToast}>
      <Image
        src="/icons/close.png"
        alt="Close" height={12} width={12}
      />
    </CloseContainer>
  );
};

/**
 *
 * @param {String} message
 * @param {Object} options type === 'info' | 'success' | 'warning' | 'error'
 * @returns
 */
export async function displayToast(message: string | ReactNode, options: ToastOptions = { type: "info" }) {
  toast.dismiss();

  return toast(
    <ToastContent {...options}>
      {message}
    </ToastContent>,
    {
      id: message?.toString(),
      icon: ({type}) =>  getToastIcon(type),
      ...options
    },
  );
}

export async function displayToastInfo(message: string) {
  return await displayToast(message, { type: "info" });
}

export async function displayToastSuccess(message: string) {
  return await displayToast(message, { type: "success" });
}

export async function displayToastError(message: string) {
  return await displayToast(message, { type: "error" });
}

const getToastIcon: (type: TypeOptions | undefined) => ReactNode = (type) => {
  switch (type) {
    case "success":
      return <Image src="/icons/success.png" alt="Success" height={24} width={24}/>;
    case "error":
      return <Image src="/icons/error-toast.png" alt="Error" width={20} height={20} />;
    default:
      return <Image src="/icons/success.png" alt="Info" height={24} width={24}/>;
  }
};

export default function CustomToastContainer() {
  return (
    <BunchesToastContainer
      autoClose={2500}
      closeButton={CloseButton}
      hideProgressBar={true}
      icon={false}
      position={"top-center"}
      transition={Slide}
      stacked={true}
    />
  );
}
