import { BOOST_MESSAGE } from "../../../gql/message/boostMessage";
import { DELETE_MESSAGE as DELETE_MESSAGE_MUTATION } from "../../../gql/message/deleteMessage";
import { FETCH_THREAD } from "../../../gql/message/threads";
import { GET_LATEST_MESSAGES, GET_OLDER_MESSAGES } from "../../../gql/message/fetchMessages";
import { MESSAGE_LIMIT } from "../../../utilities/constants/limits";
import { MODERATE_MESSAGE, UNMODERATE_MESSAGE } from "../../../gql/message/moderateMessage";
import { NEW_MESSAGE } from "../../../gql/message/newMessage";
import { PIN_MESSAGE, UNPIN_MESSAGE } from "../../../gql/message/pinMessage";
import { REACT_MESSAGE, UNREACT_MESSAGE } from "../../../gql/message/reactMessage";
import { REPORT_MESSAGE } from "../../../gql/message/reportMessage";
import {
  BoostMessageMutationVariables,
  BunchDetailsFragment,
  CreatePollMutationVariables,
  CreateRankingMutationVariables,
} from "../../../gql/types";
import { createGenericContext } from "../../../utilities/context";
import { useBunchesContext } from "../BunchesProvider";
import { handleCaughtError } from "../../../utilities";
import { displayToastInfo } from "../../shared/toasts";
import { useCallback, useReducer } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_MESSAGE,
  ADD_MESSAGES,
  ADD_THREAD,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  initialState,
  Message,
  MessageStateHash,
  reducer,
  CLEAR_MESSAGES,
  SET_HIGHLIGHTS,
  ADD_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  UPDATE_COMPOSER_TEXT,
} from "./state";
import {
  FetchBunchHighlightsQueryVariables,
  ModerateMessageMutationVariables,
  NewMessageMutationVariables,
  PinMessageMutationVariables,
  ReactMessageMutationVariables,
  ReportMessageMutationVariables,
  UnmoderateMessageMutationVariables,
  UnpinMessageMutationVariables,
} from "../../../gql/types";
import { FETCH_BUNCH_HIGHLIGHTS } from "../../../gql/bunch/fetchBunchHighlights";
import { useAuthContext } from "../AuthProvider";
import { CREATE_POLL } from "../../../gql/polls/createPoll";
import { CREATE_RANKING } from "../../../gql/ranking/createRanking";
import { GET_RANKING_DETAILS } from "../../../gql/ranking/getRankingDetails";

const [useMessagesContext, MessagesContextProvider] = createGenericContext<UseMessages>();

type UseMessages = {
  /**
   * Get a message from the store based on messageId.
   */
  getLocalMessageByMessageId: (messageId: string) => Message;
  /**
   * Fetch the latest messages for a given Bunch. Return the results as well as store them in state.
   */
  getLatestMessages: (bunchId: string) => Promise<void>;
  /**
   * Fetch older messages for a given Bunch.
   */
  getOlderMessages: (bunchId: string) => Promise<boolean | undefined>;
  /**
   * Add a new message to the chat. Optimistic object will populate local state immediately,
   * and it will be updated w/ the response data from the server.
   */
  addNewMessage: (variables: NewMessageMutationVariables, optimistic?: Message) => Promise<void>;
  /**
   * Add a new poll message into chat. Optimistic object will populate local state immediately,
   * and it will be updated w/ the response data from the server.
   */
  addNewPollMessage: (variables: CreatePollMutationVariables, optimistic?: Message) => Promise<void>;
  /**
   * Add a new ranking message into chat. Optimistic object will populate local state immediately,
   * and it will be updated w/ the response data from the server.
   */
  addNewRankingMessage: (variables: CreateRankingMutationVariables, optimistic?: Message) => Promise<void>;
  /**
   * Add a new message straight to the client state — skipping server queries & mutations.
   */
  addNewLocalMessage: (message: Message) => Promise<void>;
  /**
   * Start a thread in local state w/ a single message — where it awaits children messages to be added.
   */
  startNewThread: (message: Message) => void;
  /**
   * Delete a message.
   */
  deleteMessage: (message: Message, localOnly?: boolean) => Promise<void>;
  /**
   * Edit a message in the client state only. Useful for subscription events.
   */
  editLocalMessage: (message: Message) => Promise<void>;
  /**
   * Add a reaction to a message.
   */
  addReaction: (variables: ReactMessageMutationVariables, optimistic?: Message) => Promise<void>;
  /**
   * Remove a reaction from a message.
   */
  removeReaction: (variables: ReactMessageMutationVariables, optimistic?: Message) => Promise<void>;
  /**
   * Moderate a message.
   */
  moderateMessage: (variables: ModerateMessageMutationVariables) => Promise<void>;
  /**
   * Un-moderate a message.
   */
  unmoderateMessage: (variables: UnmoderateMessageMutationVariables) => Promise<void>;
  /**
   * Boost a message
   */
  boostMessage: (variables: BoostMessageMutationVariables) => Promise<void>;
  /**
   * Pin a message (add to highlights).
   */
  pinMessage: (variables: PinMessageMutationVariables) => Promise<void>;
  /**
   * Un-pin a message (remove it from highlights).
   */
  unpinMessage: (variables: UnpinMessageMutationVariables) => Promise<void>;
  /**
   * Report a message (does not affect local state).
   */
  reportMessage: (variables: ReportMessageMutationVariables) => Promise<any>;
  /**
   * Update the text in the composer
   */
  updateComposerText: (value: string) => void;
  /**
   * All fetched messages, stored by bunchId.
   */
  messages: MessageStateHash;
  /**
   * All fetched threads, stored by parentMessagedId.
   */
  threads: MessageStateHash;
  /**
   * All fetched highlights, stored by bunchId.
   */
  highlights: MessageStateHash;
  /**
   * Current text in the composer
   */
  composerText: string | null;
};

const useMessages = (): UseMessages => {
  const { currentUser, supportUser } = useAuthContext();
  const { supportChats, setSupportChats, currentSupportChat } = useBunchesContext();

  // Queries
  const [fetchLatestMessages, { variables: fetchVars }] = useLazyQuery(GET_LATEST_MESSAGES);
  const [fetchOlderMessages] = useLazyQuery(GET_OLDER_MESSAGES);
  const [fetchThread] = useLazyQuery(FETCH_THREAD);
  const [fetchHighlights] = useLazyQuery(FETCH_BUNCH_HIGHLIGHTS);

  // Mutations
  const [boostMutation] = useMutation(BOOST_MESSAGE);
  const [newMessage] = useMutation(NEW_MESSAGE);
  const [deleteMessageMutation] = useMutation(DELETE_MESSAGE_MUTATION);
  const [reactMessage] = useMutation(REACT_MESSAGE);
  const [unreactMessage] = useMutation(UNREACT_MESSAGE);
  const [moderateMutation] = useMutation(MODERATE_MESSAGE);
  const [unmoderateMutation] = useMutation(UNMODERATE_MESSAGE);
  const [pinMutation] = useMutation(PIN_MESSAGE);
  const [unpinMutation] = useMutation(UNPIN_MESSAGE);
  const [reportMutation] = useMutation(REPORT_MESSAGE);
  const [createPollMutation] = useMutation(CREATE_POLL);
  const [createRankingMutation] = useMutation(CREATE_RANKING);

  // State
  const [state, dispatch] = useReducer(reducer, initialState);

  // Data fetchers & methods

  const startNewThread = (message: Message) => {
    dispatch({ type: ADD_THREAD, payload: { thread: [message] } });
  };

  const getLocalMessageByMessageId = (messageId: String) => {
    for (let k in state.messages) {
      if (!(k === undefined)) {
        const subObjects = state.messages[k].filter((subObject) => {
          return messageId === subObject["messageId"];
        });

        return subObjects[0];
      }
    }
  };

  const getThreadsForMessages = useCallback(
    async (messages: Message[]) => {
      for (const message of messages) {
        if (!message.replyCount) continue;

        const { data } = await fetchThread({
          variables: { messageId: message.messageId },
        });

        const threadMessages = data?.fetchThread || [];
        if (!threadMessages.length) continue;

        dispatch({ type: ADD_THREAD, payload: { thread: threadMessages } });
      }
    },
    [fetchThread],
  );

  const getHighlights = useCallback(
    async (variables: FetchBunchHighlightsQueryVariables) => {
      try {
        const { data } = await fetchHighlights({ variables });

        if (data?.fetchBunchHighlights && data.fetchBunchHighlights.messages.length) {
          dispatch({ type: SET_HIGHLIGHTS, payload: { highlights: data.fetchBunchHighlights.messages } });
        }
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [fetchHighlights],
  );

  // Fetch latest messages — for initial bunches chat load
  const getLatestMessages = useCallback(
    async (bunchId: string) => {
      // Do not fetch if already fetching
      if (fetchVars?.bunchId === bunchId) return;

      // Clear any previously stored messages for this bunch
      dispatch({ type: CLEAR_MESSAGES, payload: { bunchId } });

      try {
        const { data } = await fetchLatestMessages({
          variables: { bunchId, limit: MESSAGE_LIMIT },
        });

        if (!data?.getMessages?.messages) return;

        dispatch({ type: ADD_MESSAGES, payload: { messages: data.getMessages.messages } });

        // Fetch any threads attached to these messages
        await getThreadsForMessages(data.getMessages.messages);

        if (currentUser) {
          // Fetch associated highlights
          await getHighlights({ bunchId, userId: currentUser.userId });
        }
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [fetchVars, fetchLatestMessages, getThreadsForMessages, currentUser, getHighlights],
  );

  // Fetch latest messages — for initial bunches chat load
  const getOlderMessages = useCallback(
    async (bunchId: string) => {
      const existingMessages = state.messages[bunchId];
      if (!existingMessages) return;

      const oldestMessageId = existingMessages[existingMessages.length - 1].messageId;

      try {
        const response = await fetchOlderMessages({
          variables: { bunchId, limit: MESSAGE_LIMIT, oldestMessageId, include: false },
        });

        if (!response.data?.getMessages.messages) return;

        const { messages } = response.data.getMessages;
        if (!messages.length) return false;

        dispatch({ type: ADD_MESSAGES, payload: { messages } });

        // Fetch any threads attached to these messages
        await getThreadsForMessages(messages);

        return true;
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [fetchOlderMessages, state.messages, getThreadsForMessages],
  );

  // Add a single new message. If optimistic response is provided,
  // append it immediately, and edit the message after a successful response.
  const addNewMessage = useCallback(
    async (variables: NewMessageMutationVariables, optimistic?: Message) => {
      try {
        if (optimistic) {
          dispatch({ type: ADD_MESSAGE, payload: { message: optimistic } });
        }

        const { data } = await newMessage({ variables });
        if (!data?.newMessage) return;

        if (optimistic) {
          dispatch({ type: EDIT_MESSAGE, payload: { message: data.newMessage } });
        } else {
          dispatch({ type: ADD_MESSAGE, payload: { message: data.newMessage } });
        }

        // Edit replyCount for parent
        if (variables.parentMessageId) {
          const parentMessage = state.messages[variables.bunchId].find(
            (m) => m.messageId === variables.parentMessageId,
          );

          if (parentMessage) {
            dispatch({
              type: EDIT_MESSAGE,
              payload: { message: { ...parentMessage, replyCount: parentMessage.replyCount + 1 } },
            });
          }
        }

        if (currentUser && supportUser && data.newMessage.bunchId === currentSupportChat?.bunch.bunchId) {
          let updatedBunch: Partial<BunchDetailsFragment> = {
            latestMessage: data.newMessage,
          };

          updatedBunch.lastMessageRead = newMessage;
          updatedBunch.lastMessageAt = new Date().toISOString();

          // Put support chat with the new message at the top of the chat list
          const updatedSupportChats = (data && data.newMessage && supportChats.length ? supportChats : []).map(
            (chat) => {
              if (chat.bunch.bunchId === data.newMessage.bunchId) {
                return {
                  ...chat,
                  ...updatedBunch,
                  latestMessage: data.newMessage,
                };
              }

              return chat;
            },
          );

          setSupportChats(
            updatedSupportChats.sort((x, y) =>
              x.bunch.bunchId === data.newMessage.bunchId ? -1 : y.bunch.bunchId === data.newMessage.bunchId ? 1 : 0,
            ),
          );
        }
      } catch (err) {
        if (optimistic) {
          dispatch({ type: DELETE_MESSAGE, payload: { message: optimistic } });
        }
        handleCaughtError(err);
      }
    },
    [
      newMessage,
      state.messages,
      currentSupportChat?.bunch.bunchId,
      currentUser,
      setSupportChats,
      supportChats,
      supportUser,
    ],
  );

  const addNewPollMessage = useCallback(
    async (variables: CreatePollMutationVariables, optimistic?: Message) => {
      try {
        if (optimistic) {
          dispatch({ type: ADD_MESSAGE, payload: { message: optimistic } });
        }

        const { data } = await createPollMutation({ variables });
        if (!data?.createPoll) return;

        if (optimistic) {
          dispatch({ type: EDIT_MESSAGE, payload: { message: data.createPoll } });
        } else {
          dispatch({ type: ADD_MESSAGE, payload: { message: data.createPoll } });
        }

        // Edit replyCount for parent
        if (variables.parentMessageId) {
          const parentMessage = state.messages[variables.bunchId].find(
            (m) => m.messageId === variables.parentMessageId,
          );

          if (parentMessage) {
            dispatch({
              type: EDIT_MESSAGE,
              payload: { message: { ...parentMessage, replyCount: parentMessage.replyCount + 1 } },
            });
          }
        }
      } catch (err) {
        if (optimistic) {
          dispatch({ type: DELETE_MESSAGE, payload: { message: optimistic } });
        }
        handleCaughtError(err);
      }
    },
    [createPollMutation, state.messages],
  );

  const addNewRankingMessage = useCallback(
    async (variables: CreateRankingMutationVariables, optimistic?: Message) => {
      try {
        // Clear composerText
        dispatch({ type: UPDATE_COMPOSER_TEXT, payload: { composerText: "" } });

        if (optimistic) {
          dispatch({ type: ADD_MESSAGE, payload: { message: optimistic } });
        }

        const { data } = await createRankingMutation({ variables, refetchQueries: [GET_RANKING_DETAILS] });
        if (!data?.createRanking) return;

        if (optimistic) {
          dispatch({ type: EDIT_MESSAGE, payload: { message: data.createRanking } });
        } else {
          dispatch({ type: ADD_MESSAGE, payload: { message: data.createRanking } });
        }

        //Edit replyCount for parent
        if (variables.parentMessageId) {
          const parentMessage = state.messages[variables.bunchId].find(
            (m) => m.messageId === variables.parentMessageId,
          );

          if (parentMessage) {
            dispatch({
              type: EDIT_MESSAGE,
              payload: { message: { ...parentMessage, replyCount: parentMessage.replyCount + 1 } },
            });
          }
        }
      } catch (err) {
        if (optimistic) {
          dispatch({ type: DELETE_MESSAGE, payload: { message: optimistic } });
        }
        handleCaughtError(err);
      }
    },
    [createRankingMutation, state.messages],
  );

  // Add a single new message locally only – for use with subscriptions
  const addNewLocalMessage = useCallback(
    async (message: Message) => {
      try {
        dispatch({ type: ADD_MESSAGE, payload: { message } });

        // Edit replyCount for parent
        if (message.parentMessageId) {
          const parentMessage = state.messages[message.bunchId].find((m) => m.messageId === message.parentMessageId);

          if (parentMessage) {
            dispatch({
              type: EDIT_MESSAGE,
              payload: { message: { ...parentMessage, replyCount: parentMessage.replyCount + 1 } },
            });
          }
        }
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [state.messages],
  );

  const deleteMessage = useCallback(
    async (message: Message, localOnly?: boolean) => {
      try {
        dispatch({ type: DELETE_MESSAGE, payload: { message } });

        if (!localOnly) {
          await deleteMessageMutation({ variables: { messageId: message.messageId } });
        }

        if (message.parentMessageId) {
          const parentMessage = state.messages[message.bunchId].find((m) => m.messageId === message.parentMessageId);

          if (parentMessage) {
            dispatch({
              type: EDIT_MESSAGE,
              payload: { message: { ...parentMessage, replyCount: Math.max(parentMessage.replyCount - 1, 0) } },
            });
          }
        }
      } catch (err) {
        // Add it back if the server call failed.
        addNewLocalMessage(message);
        handleCaughtError(err);
      }
    },
    [addNewLocalMessage, deleteMessageMutation, state.messages],
  );

  // Edit a local message (useful for subscriptions)
  const editLocalMessage = useCallback(
    async (message: Message) => {
      try {
        dispatch({ type: EDIT_MESSAGE, payload: { message } });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [dispatch],
  );

  const addReaction = useCallback(
    async (variables: ReactMessageMutationVariables, optimistic?: Message) => {
      try {
        if (optimistic) {
          dispatch({ type: EDIT_MESSAGE, payload: { message: optimistic } });
        }

        const { data } = await reactMessage({ variables });
        if (!data?.reactMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.reactMessage } });

        const messageIsPinned = state.highlights[data.reactMessage.bunchId]?.some(
          (h) => h.messageId === data.reactMessage.messageId,
        );

        if (messageIsPinned && currentUser) {
          getHighlights({ bunchId: data.reactMessage.bunchId, userId: currentUser.userId });
        }
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [currentUser, getHighlights, reactMessage, state.highlights],
  );

  const removeReaction = useCallback(
    async (variables: ReactMessageMutationVariables, optimistic?: Message) => {
      try {
        if (optimistic) {
          dispatch({ type: EDIT_MESSAGE, payload: { message: optimistic } });
        }

        const { data } = await unreactMessage({ variables });
        if (!data?.unreactMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.unreactMessage } });

        const messageIsPinned = state.highlights[data.unreactMessage.bunchId]?.some(
          (h) => h.messageId === data.unreactMessage.messageId,
        );

        if (messageIsPinned && currentUser) {
          getHighlights({ bunchId: data.unreactMessage.bunchId, userId: currentUser.userId });
        }
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [currentUser, getHighlights, state.highlights, unreactMessage],
  );

  const moderateMessage = useCallback(
    async (variables: ModerateMessageMutationVariables) => {
      try {
        const { data } = await moderateMutation({ variables });
        if (!data?.moderateMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.moderateMessage } });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [moderateMutation],
  );

  const unmoderateMessage = useCallback(
    async (variables: UnmoderateMessageMutationVariables) => {
      try {
        const { data } = await unmoderateMutation({ variables });
        if (!data?.unmoderateMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.unmoderateMessage } });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [unmoderateMutation],
  );

  const boostMessage = useCallback(
    async (variables: BoostMessageMutationVariables) => {
      try {
        const { data } = await boostMutation({ variables });
        if (!data?.boostMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.boostMessage } });
        dispatch({ type: ADD_HIGHLIGHT, payload: { highlight: data.boostMessage } });
        await displayToastInfo("Message boosted");
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [boostMutation],
  );

  const pinMessage = useCallback(
    async (variables: PinMessageMutationVariables) => {
      try {
        const { data } = await pinMutation({ variables });
        if (!data?.pinMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.pinMessage } });
        dispatch({ type: ADD_HIGHLIGHT, payload: { highlight: data.pinMessage } });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [pinMutation],
  );

  const unpinMessage = useCallback(
    async (variables: UnpinMessageMutationVariables) => {
      try {
        const { data } = await unpinMutation({ variables });
        if (!data?.unpinMessage) return;

        dispatch({ type: EDIT_MESSAGE, payload: { message: data.unpinMessage } });
        dispatch({ type: REMOVE_HIGHLIGHT, payload: { highlight: data.unpinMessage } });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [unpinMutation],
  );

  const reportMessage = useCallback(
    async (variables: ReportMessageMutationVariables) => {
      try {
        const { data } = await reportMutation({ variables });
        if (!data?.reportMessage) return null;
        return data.reportMessage;
      } catch (err) {
        handleCaughtError(err);
        return null;
      }
    },
    [reportMutation],
  );

  const updateComposerText = (value: string) => {
    dispatch({ type: UPDATE_COMPOSER_TEXT, payload: { composerText: value } });
  };

  return {
    getLocalMessageByMessageId,
    getLatestMessages,
    getOlderMessages,
    addNewMessage,
    addNewLocalMessage,
    addNewPollMessage,
    addNewRankingMessage,
    updateComposerText,
    editLocalMessage,
    startNewThread,
    deleteMessage,
    addReaction,
    removeReaction,
    moderateMessage,
    unmoderateMessage,
    boostMessage,
    pinMessage,
    unpinMessage,
    reportMessage,
    getThreadsForMessages,
    messages: state.messages,
    threads: state.threads,
    highlights: state.highlights,
    composerText: state.composerText,
  };
};

interface Props {
  children: JSX.Element;
}

const MessagesProvider = ({ children }: Props) => {
  const bunches = useMessages();

  return <MessagesContextProvider value={bunches}>{children}</MessagesContextProvider>;
};

export { useMessagesContext, MessagesProvider };
