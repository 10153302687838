import { gql, TypedDocumentNode } from "@apollo/client";
import { MESSAGE_DETAILS } from "../fragments";
import { CreateRankingMutation, CreateRankingMutationVariables } from "../types";

export const CREATE_RANKING: TypedDocumentNode<CreateRankingMutation, CreateRankingMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation createRanking(
    $bunchId: ID!
    $clientMessageId: ID
    $clientRankingId: ID
    $ranking: RankingInput!
    $parentMessageId: ID
    $content: ContentInput
  ) {
    createRanking(
      bunchId: $bunchId
      clientMessageId: $clientMessageId
      clientRankingId: $clientRankingId
      ranking: $ranking
      parentMessageId: $parentMessageId
      content: $content
    ) {
      ...MessageDetails
    }
  }
`;
