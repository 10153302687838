import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { FetchBunchHighlightsQuery, FetchBunchHighlightsQueryVariables } from "../types";

export const FETCH_BUNCH_HIGHLIGHTS: TypedDocumentNode<
  FetchBunchHighlightsQuery,
  FetchBunchHighlightsQueryVariables
> = gql`
  ${MESSAGE_DETAILS}
  query fetchBunchHighlights($bunchId: ID!, $userId: ID!) {
    fetchBunchHighlights(bunchId: $bunchId, userId: $userId) {
      bunchId
      messages {
        ...MessageDetails
        pinnedDateDisplayText
      }
      userId
    }
  }
`;
