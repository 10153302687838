import { useCallback } from "react";
import { Bunch } from "../../types";
import { MutationFunctionOptions, useMutation } from "@apollo/client";
import { createGenericContext } from "../../utilities/context";
import { useBunchesContext } from "./BunchesProvider";
import { useAuthContext } from "./AuthProvider";
import { UPDATE_BUNCH } from "../../gql/bunch/updateBunch";
import { handleCaughtError } from "../../utilities";
import { UpdateBunchMutation, UpdateBunchMutationVariables } from "../../gql/types";

type UseBunchOwner = {
  isBunchOwner: () => boolean;
  updateBunch: (
    params: MutationFunctionOptions<UpdateBunchMutation, UpdateBunchMutationVariables>
  ) => Promise<Bunch | null>;
};

const [useBunchOwnerContext, BunchOwnerContextProvider] = createGenericContext<UseBunchOwner>();

const useBunchOwner = (): UseBunchOwner => {
  const { currentUser } = useAuthContext();
  const { userIsCreator } = useBunchesContext();

  const [updateBunchMutation] = useMutation(UPDATE_BUNCH);

  const isBunchOwner = useCallback(() => {
    if (!currentUser) return false;

    return userIsCreator(currentUser);
  }, [currentUser, userIsCreator]);

  const updateBunch = useCallback(
    async (params: MutationFunctionOptions<UpdateBunchMutation, UpdateBunchMutationVariables>) => {
      try {
        const { data } = await updateBunchMutation(params);

        if (data?.updateBunch) {
          return data.updateBunch;
        }

        return null;
      } catch (err) {
        handleCaughtError(err);
        return null;
      }
    },
    [updateBunchMutation]
  );

  return {
    isBunchOwner,
    updateBunch,
  };
};

interface Props {
  children: JSX.Element;
}

const BunchOwnerProvider = ({ children }: Props) => {
  const auth = useBunchOwner();

  return <BunchOwnerContextProvider value={auth}>{children}</BunchOwnerContextProvider>;
};

export { useBunchOwnerContext, BunchOwnerProvider };
