import React, { useCallback } from "react";
import { createGenericContext } from "../../utilities/context";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { abi } from "../../utilities/web3/parseLogs";
import { Address, getAddress, parseEther } from "viem";
import { displayToastError } from "../shared/toasts";

type UseBalls = {
  sendBalls: (address: Address, amount: string) => Promise<boolean>;
  balance: bigint | undefined;
  loadingBalance: boolean;
};

const [useBallsContext, BallsContextProvider] = createGenericContext<UseBalls>();

const useBalls = (): UseBalls => {
  const account = useAccount();
  const { writeContractAsync } = useWriteContract({
    mutation: {
      onError(error) {
        console.error({ error });
        // @ts-ignore
        switch (error.cause.code) {
          case 4001:
            displayToastError("You rejected the boost.");
            break;
          default:
            displayToastError("Something went wrong with that boost.");
        }
      },
    },
  });

  const ballsAddress = getAddress(process.env.NEXT_PUBLIC_BALLS_ADDRESS ?? "");

  const { data: balance, isPending: loadingBalance } = useReadContract({
    address: ballsAddress,
    abi,
    functionName: "balanceOf",
    args: [account.address ?? "0x"],
  });

  const sendBalls = useCallback(
    async (address: Address, amount: string) => {
      try {
        await writeContractAsync({
          abi,
          address: ballsAddress,
          functionName: "transfer",
          args: [getAddress(address), parseEther(amount)],
        });
      } catch (err) {
        console.error({ err });
        return false;
      }
      return true;
    },
    [writeContractAsync, ballsAddress],
  );

  return {
    sendBalls,
    balance,
    loadingBalance,
  };
};

interface Props {
  children: JSX.Element;
}

const BallsProvider = ({ children }: Props) => {
  const balls = useBalls();

  return <BallsContextProvider value={balls}>{children}</BallsContextProvider>;
};

export { useBallsContext, BallsProvider };
