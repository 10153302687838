import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { NewMessageMutation, NewMessageMutationVariables } from "../types";
import { TypedDocumentNode } from "@apollo/client";

export const NEW_MESSAGE: TypedDocumentNode<NewMessageMutation, NewMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation newMessage(
    $bunchId: ID!
    $userId: ID!
    $content: ContentInput!
    $type: String!
    $isPinned: Boolean
    $expired: String
    $parentMessageId: ID
    $clientMessageId: ID
    $transactionId: ID
  ) {
    newMessage(
      bunchId: $bunchId
      userId: $userId
      transactionId: $transactionId
      content: $content
      type: $type
      isPinned: $isPinned
      expired: $expired
      parentMessageId: $parentMessageId
      clientMessageId: $clientMessageId
    ) {
      ...MessageDetails
    }
  }
`;
