import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { DeleteMessageMutation, DeleteMessageMutationVariables } from "../types";

export const DELETE_MESSAGE: TypedDocumentNode<DeleteMessageMutation, DeleteMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation deleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId) {
      ...MessageDetails
    }
  }
`;
