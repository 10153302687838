import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";
import {
  PinBunchMutation,
  PinBunchMutationVariables
} from "../types";

export const PIN_BUNCH: TypedDocumentNode<PinBunchMutation, PinBunchMutationVariables> = gql`
  ${BUNCH_DETAILS}
  mutation pinBunch($bunchId: ID!, $pin: Boolean!) {
    pinBunch(bunchId: $bunchId, pin: $pin) {
      ...BunchDetails
    }
  }
`;
