import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";
import { UpdateBunchMutation, UpdateBunchMutationVariables } from "../types";

export const UPDATE_BUNCH: TypedDocumentNode<UpdateBunchMutation, UpdateBunchMutationVariables> = gql`
  ${BUNCH_DETAILS}
  mutation updateBunch(
    $bunchId: ID!
    $name: String
    $description: String
    $discoverBackgroundUrl: String
    $discoverIconUrl: String
  ) {
    updateBunch(
      bunchId: $bunchId
      name: $name
      description: $description
      discoverBackgroundUrl: $discoverBackgroundUrl
      discoverIconUrl: $discoverIconUrl
    ) {
      ...BunchDetails
    }
  }
`;
