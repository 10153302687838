import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { FriendRequest, UserDetailsFragment } from "../../gql/types";
import { BLOCK_USER, FETCH_BLOCKED_USERS, UNBLOCK_USER } from "../../gql/user/blockUser";
import {
  ACCEPT_FRIEND_REQUEST,
  DENY_FRIEND_REQUEST,
  GET_FRIENDS_FOR_USER,
  GET_FRIEND_REQUESTS,
} from "../../gql/user/friendUser";
import { handleCaughtError } from "../../utilities";
import { createGenericContext } from "../../utilities/context";
import { useAuthContext } from "./AuthProvider";

type UseUser = {
  friends: UserDetailsFragment[];
  fetchFriends: () => void;
  friendRequests: FriendRequest[];
  /**
   * Fetch the available friends requests for the current user and store them in local state.
   */
  fetchFriendRequests: () => Promise<void>;
  /**
   * Returns true if successful, otherwise false.
   */
  acceptFriendRequest: (subjectId: string) => Promise<boolean>;
  /**
   * Returns true if successful, otherwise false.
   */
  denyFriendRequest: (subjectId: string) => Promise<boolean>;
  blockedUsers: UserDetailsFragment[];
  blockUser: (userIdToBlock: string, force?: boolean) => Promise<UserDetailsFragment>;
  queueUserToBlock: (userIdToBlock: string) => void;
  undoBlock: (userIdToBlock: string) => void;
  unblockUser: (userIdToUnblock: string) => Promise<UserDetailsFragment | null>;
};

const [useUserContext, UserContextProvider] = createGenericContext<UseUser>();

const useUser = (): UseUser => {
  const { currentUser } = useAuthContext();

  // User friends and friend requests
  const [fetchFriendsQuery] = useLazyQuery(GET_FRIENDS_FOR_USER);
  const [getFriendRequests] = useLazyQuery(GET_FRIEND_REQUESTS);
  const [acceptMutation] = useMutation(ACCEPT_FRIEND_REQUEST);
  const [denyMutation] = useMutation(DENY_FRIEND_REQUEST);

  const [friends, setFriends] = useState<UserDetailsFragment[]>([]);
  const [friendRequests, setFriendRequests] = useState<FriendRequest[]>([]);

  const fetchFriends = useCallback(async () => {
    if (!currentUser) return;

    try {
      const { data } = await fetchFriendsQuery({ variables: { callerId: currentUser.userId } });
      const friendsList = data?.getFriendsForUser || [];
      const sortedFriends = [...friendsList].sort((a, b) => a.username.localeCompare(b.username));

      setFriends(sortedFriends);
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [currentUser, fetchFriendsQuery]);

  const fetchFriendRequests = useCallback(async () => {
    if (!currentUser) return;

    try {
      const { data } = await getFriendRequests({ variables: { userId: currentUser.userId } });
      const requests = data?.getFriendRequests || [];

      setFriendRequests(requests);
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [currentUser, getFriendRequests]);

  const acceptFriendRequest = useCallback(
    async (subjectId: string) => {
      if (!currentUser) return false;

      try {
        await acceptMutation({ variables: { callerId: currentUser.userId, subjectId } });
        const newRequests = friendRequests.filter((r) => r.user.userId !== subjectId);

        setFriendRequests(newRequests);
        return true;
      } catch (err) {
        await handleCaughtError(err);
      }

      return false;
    },
    [acceptMutation, currentUser, friendRequests]
  );

  const denyFriendRequest = useCallback(
    async (subjectId: string) => {
      if (!currentUser) return false;

      try {
        await denyMutation({ variables: { callerId: currentUser.userId, subjectId } });
        const newRequests = friendRequests.filter((r) => r.user.userId !== subjectId);

        setFriendRequests(newRequests);
        return true;
      } catch (err) {
        await handleCaughtError(err);
      }

      return false;
    },
    [denyMutation, currentUser, friendRequests]
  );

  useEffect(() => {
    fetchFriends();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Blocking / unblocking users
  const [fetchBlockedUsers, { data: blockedUsersData }] = useLazyQuery(FETCH_BLOCKED_USERS);
  const [blockedUsers, setBlockedUsers] = useState<UserDetailsFragment[]>(blockedUsersData?.fetchBlockedUsers || []);
  const [toBlock, setToBlock] = useState<UserDetailsFragment["userId"][]>([]);
  const [blockUserMutation] = useMutation(BLOCK_USER);
  const [unblockUserMutation] = useMutation(UNBLOCK_USER);
  const toBlockRef = useRef(toBlock);

  const refetch = useCallback(async () => {
    if (!currentUser) return;

    try {
      const users = await fetchBlockedUsers({ variables: { userId: currentUser.userId } });
      setBlockedUsers(users.data?.fetchBlockedUsers || []);
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [currentUser, fetchBlockedUsers]);

  useEffect(() => {
    toBlockRef.current = toBlock;
  }, [toBlock]);

  useEffect(() => {
    if (currentUser) {
      refetch();
      fetchFriends();
    }
  }, [currentUser, refetch, fetchFriends]);

  const queueUserToBlock = useCallback(
    (userIdToBlock: string) => {
      // En-queue the user to be blocked
      setToBlock([...toBlock, userIdToBlock]);
    },
    [toBlock]
  );

  const undoBlock = useCallback(
    (userIdToBlock: string) => {
      if (!toBlock) return;
      // Stop the user from getting blocked by removing their ID from the queue
      setToBlock([...toBlock.filter((id: string) => id !== userIdToBlock)]);
    },
    [toBlock]
  );

  const blockUser = useCallback(
    async (userIdToBlock: string, force: boolean = false) => {
      if (!currentUser) return;

      const queuedUsers = toBlockRef?.current || [];

      // If not queued for blocking, do not block. Allow force override
      if (!queuedUsers.includes(userIdToBlock) && force === false) {
        return;
      }

      try {
        const blockuserResponse = await blockUserMutation({
          variables: { userId: currentUser.userId, userIdToBlock },
        });

        const user = blockuserResponse.data.blockUser;

        setBlockedUsers([...blockedUsers, user]);

        return user;
      } catch (err) {
        await handleCaughtError(err);
      }
    },
    [blockUserMutation, currentUser, blockedUsers]
  );

  const unblockUser = async (userIdToUnblock: string) => {
    if (!blockedUsers || !currentUser) return;
    try {
      const unblockuserResponse = await unblockUserMutation({
        variables: { userId: currentUser.userId, userIdToUnblock },
      });

      if (!unblockuserResponse || !unblockuserResponse.data || !unblockuserResponse.data.unblockUser) return;
      const user = unblockuserResponse.data.unblockUser;

      setBlockedUsers(blockedUsers.filter((u) => u.userId !== user?.userId));

      return user;
    } catch (err) {
      await handleCaughtError(err);
    }

    return null;
  };

  return {
    blockedUsers,
    blockUser,
    queueUserToBlock,
    undoBlock,
    unblockUser,
    friends,
    fetchFriends,
    fetchFriendRequests,
    friendRequests,
    acceptFriendRequest,
    denyFriendRequest,
  };
};

interface Props {
  children: JSX.Element;
}

const UserProvider = ({ children }: Props) => {
  const user = useUser();

  return <UserContextProvider value={user}>{children}</UserContextProvider>;
};

export { useUserContext, UserProvider };
