import { Dispatch, SetStateAction, useState } from "react";
import { createGenericContext } from "../../utilities/context";

type UseBunchDetails = {
  didLeave: boolean;
  setDidLeave: Dispatch<SetStateAction<boolean>>;
  presences: any;
  setPresences: Dispatch<SetStateAction<any>>;
};

const [useBunchDetailsContext, BunchDetailsContextProvider] = createGenericContext<UseBunchDetails>();

const useBunchDetails = (): UseBunchDetails => {
  const [didLeave, setDidLeave] = useState(false);
  const [presences, setPresences] = useState({});

  return {
    didLeave,
    setDidLeave,
    presences,
    setPresences,
  };
};

interface Props {
  children: JSX.Element;
}

const BunchDetailsProvider = ({ children }: Props) => {
  const details = useBunchDetails();

  return <BunchDetailsContextProvider value={details}>{children}</BunchDetailsContextProvider>;
};

export { useBunchDetailsContext, BunchDetailsProvider };
