import { useCallback } from "react";
import { createGenericContext } from "../../utilities/context";
import { useRouter } from "next/router";
import { useTransactionsContext } from "./TransactionProvider";

type UseDeepLink = {
  handleBunchesUrl: (url: string) => void;
};

const [useDeepLinkContext, DeepLinkContextProvider] = createGenericContext<UseDeepLink>();

const useDeepLink = (): UseDeepLink => {
  const router = useRouter();
  const { signRequest } = useTransactionsContext();

  const handleTransaction = useCallback(
    (terms: Array<string>) => {
      switch (terms[0]) {
        case "confirm":
          const transactionId = terms[1];
          signRequest(transactionId);
          break;
      }
    },
    [signRequest],
  );

  const handleBunchesUrl = useCallback(
    (url: string) => {
      if (!url) return;
      if (!url.includes("bunches://")) router.push(url);

      const suffix = url.replace("bunches://", "");
      const terms = suffix.split("/");
      const primaryRoute = terms[0];

      if (!primaryRoute) return;

      switch (primaryRoute) {
        case "transaction":
          handleTransaction(terms.slice(1));
          break;
      }
    },
    [handleTransaction, router],
  );

  return {
    handleBunchesUrl,
  };
};

interface Props {
  children: JSX.Element;
}

const DeepLinkProvider = ({ children }: Props) => {
  const deepLink = useDeepLink();

  return <DeepLinkContextProvider value={deepLink}>{children}</DeepLinkContextProvider>;
};

export { useDeepLinkContext, DeepLinkProvider };
