import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createGenericContext } from "../../utilities/context";
import { fileToBase64 } from "../../utilities";
import { BunchDetailsFragment } from "../../gql/types";

type UseOnboarding = {
  bunchToJoin: BunchDetailsFragment | null;
  setBunchToJoin: Dispatch<SetStateAction<BunchDetailsFragment | null>>;
  postOnboardingRoute: string | null;
  setPostOnboardingRoute: Dispatch<SetStateAction<string | null>>;
  userProfilePicture: File | null;
  setUserProfilePicture: Dispatch<SetStateAction<File | null>>;
  base64ProfilePicture: string | null;
};

const [useOnboardingContext, OnboardingContextProvider] = createGenericContext<UseOnboarding>();

const useOnboarding = (): UseOnboarding => {
  const [bunchToJoin, setBunchToJoin] = useState<BunchDetailsFragment | null>(null);
  const [postOnboardingRoute, setPostOnboardingRoute] = useState<string | null>(null);
  const [userProfilePicture, setUserProfilePicture] = useState<File | null>(null);
  const [base64ProfilePicture, setBase64ProfilePicture] = useState<string | null>(null);

  useEffect(() => {
    if (!userProfilePicture) return;
    fileToBase64(userProfilePicture)?.then((base64) => setBase64ProfilePicture(base64 as any));
  }, [userProfilePicture]);

  return {
    bunchToJoin,
    setBunchToJoin,
    postOnboardingRoute,
    setPostOnboardingRoute,
    userProfilePicture,
    setUserProfilePicture,
    base64ProfilePicture,
  };
};

interface Props {
  children: JSX.Element;
}

const OnboardingProvider = ({ children }: Props) => {
  const onboarding = useOnboarding();

  return <OnboardingContextProvider value={onboarding}>{children}</OnboardingContextProvider>;
};

export { useOnboardingContext, OnboardingProvider };
