import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { ReportMessageMutation, ReportMessageMutationVariables } from "../types";

export const REPORT_MESSAGE: TypedDocumentNode<ReportMessageMutation, ReportMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation reportMessage($userId: ID!, $messageId: ID!) {
    reportMessage(userId: $userId, messageId: $messageId) {
      ...MessageDetails
    }
  }
`;
