import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import {
  ReactMessageMutation,
  ReactMessageMutationVariables,
  UnreactMessageMutation,
  UnreactMessageMutationVariables,
} from "../types";

export const REACT_MESSAGE: TypedDocumentNode<ReactMessageMutation, ReactMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation reactMessage($content: String!, $messageId: ID!, $userId: ID!) {
    reactMessage(content: $content, messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;

export const UNREACT_MESSAGE: TypedDocumentNode<UnreactMessageMutation, UnreactMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation unreactMessage($content: String!, $messageId: ID!, $userId: ID!) {
    unreactMessage(content: $content, messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;
