import gql from "graphql-tag";
import { FetchNotificationsQuery, FetchNotificationsQueryVariables } from "../types";
import { TypedDocumentNode } from "@apollo/client";

export const FETCH_NOTIFICATIONS: TypedDocumentNode<FetchNotificationsQuery, FetchNotificationsQueryVariables> = gql`
  query fetchNotifications($userId: ID!) {
    fetchNotifications(userId: $userId) {
      notificationId
      userId
      type
      title
      body
      relatedBunchId
      relatedMessageId
      relatedUserId
      relatedBunch {
        bunchId
        name
        discoverIconUrl
      }
      relatedMessage {
        content {
          text
          previewText
        }
      }
      relatedUser {
        userId
        username
        profilePictureUrl
        walletAddress
      }
      seen
      updatedAt
      insertedAt
    }
  }
`;
