import gql from "graphql-tag";
import { FetchBunchMembersQuery, FetchBunchMembersQueryVariables } from "../types";
import { TypedDocumentNode } from "@apollo/client";

export const FETCH_BUNCH_MEMBERS: TypedDocumentNode<FetchBunchMembersQuery, FetchBunchMembersQueryVariables> = gql`
  query fetchBunchMembers($bunchId: ID!) {
    fetchMembersForBunch(bunchId: $bunchId) {
      userId
      username
    }
  }
`;
