import { useCallback, useEffect, useState } from "react";
import { Notification } from "../../types";
import { MutationResult, useLazyQuery, useMutation } from "@apollo/client";
import { createGenericContext } from "../../utilities/context";
import { useAuthContext } from "./AuthProvider";
import { FETCH_NOTIFICATIONS } from "../../gql/notifications/fetchNotifications";
import { UiNotification } from "../../gql/types";
import { handleCaughtError } from "../../utilities";
import { MARK_NOTIFICATIONS_SEEN } from "../../gql/notifications/markNotificationsSeen";

type UseNotifications = {
  notifications: UiNotification[];
  markNotificationAsSeen: (notificationIds: Notification["notificationId"][]) => Promise<MutationResult | null>;
};

const [useNotificationContext, NotificationContextProvider] = createGenericContext<UseNotifications>();

const useNotifications = (): UseNotifications => {
  const { currentUser } = useAuthContext();
  const [notifQuery, { called, loading }] = useLazyQuery(FETCH_NOTIFICATIONS);
  const [markAsSeen] = useMutation(MARK_NOTIFICATIONS_SEEN);

  const [notifications, setNotifications] = useState<UiNotification[]>([]);

  const callQuery = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await notifQuery({
        variables: { userId: currentUser.userId },
      });

      if (response && response.data && response.data.fetchNotifications) {
        setNotifications(response.data.fetchNotifications || []);
      }
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [notifQuery, currentUser]);

  // Fetch on mount
  useEffect(() => {
    if (called || loading) return;
    if (!currentUser) return;

    callQuery();
  }, [called, loading, callQuery, currentUser]);

  const markNotificationAsSeen = useCallback(
    async (notificationIds = []) => {
      if (!notificationIds.length) return;

      const response = await markAsSeen({ variables: { notificationIds } });
      const items = response?.data?.markNotificationsSeen || [];

      const updatedNotifications = notifications.map((n) => {
        if (items.some((item) => item && item.notificationId === n.notificationId)) {
          return {
            ...n,
            seen: true,
          };
        }

        return n;
      });

      setNotifications(updatedNotifications);

      return updatedNotifications;
    },
    [markAsSeen, notifications]
  );

  return {
    notifications,
    markNotificationAsSeen,
  };
};

interface Props {
  children: JSX.Element;
}

const NotificationProvider = ({ children }: Props) => {
  const notifs = useNotifications();

  return <NotificationContextProvider value={notifs}>{children}</NotificationContextProvider>;
};

export { useNotificationContext, NotificationProvider };
