import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import isEqual from "lodash.isequal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UserDetailsFragment } from "../../gql/types";
import { FETCH_USER } from "../../gql/user/fetchUser";
import { UPDATE_USER } from "../../gql/user/updateUser";
import { SUPPORT_USER_ID } from "../../utilities/constants/uuids";
import { createGenericContext } from "../../utilities/context";

type UseAuth = {
  didLogout: boolean | false;
  setDidLogout: Dispatch<SetStateAction<boolean | false>>;
  token: string | null;
  setAuthToken: (data: UserDetailsFragment | null) => void;
  currentUser: UserDetailsFragment | null;
  setCurrentUser: (user: UserDetailsFragment | null) => void;
  supportUser: UserDetailsFragment | null;
  setSupportUser: (user: UserDetailsFragment | null) => void;
};

const [useAuthContext, AuthContextProvider] = createGenericContext<UseAuth>();

const useAuth = (): UseAuth => {
  const [didLogout, setDidLogout] = useState(false);
  const [token, setToken] = useState(Cookies.get("token") || null);
  const [currentUser, setCurrentUser] = useState<UserDetailsFragment | null>(null);
  const [supportUser, setSupportUser] = useState<UserDetailsFragment | null>(null);

  const fetchSupportUser = useQuery(FETCH_USER, { variables: { userId: SUPPORT_USER_ID } });
  const [fetchUser, { data }] = useLazyQuery(FETCH_USER);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateUser, updateResult] = useMutation(UPDATE_USER);

  const setAuthToken = (data: UserDetailsFragment | null) => {
    if (data && data.token) {
      const { token } = data;
      Cookies.set("token", token);
      setToken(token);

      Cookies.set("hasLoggedIn", "true");
    } else {
      Cookies.remove("token");
      setToken(null);
    }
  };

  useEffect(() => {
    if (!fetchSupportUser.data || !token || currentUser) return;

    fetchUser({ variables: { token: token }, fetchPolicy: "no-cache" });
  }, [token, fetchUser, currentUser, fetchSupportUser, supportUser]);

  useEffect(() => {
    if (!fetchSupportUser.data || !data?.fetchUser || (currentUser && isEqual(data.fetchUser, currentUser))) return;

    Cookies.set("currentUser", JSON.stringify(data.fetchUser));
    if (data.fetchUser.isAdmin) setSupportUser(fetchSupportUser.data.fetchUser);
    setCurrentUser(data.fetchUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setCurrentUser, fetchSupportUser, setSupportUser]);

  useEffect(() => {
    if (
      !updateResult ||
      !updateResult.data ||
      !updateResult.data.updateUser ||
      isEqual(updateResult.data.updateUser, currentUser)
    )
      return;
    Cookies.set("currentUser", JSON.stringify(updateResult.data.updateUser));
    setCurrentUser(updateResult.data.updateUser);
  }, [updateResult, currentUser, setCurrentUser]);

  return {
    didLogout,
    setDidLogout,
    token,
    setAuthToken,
    currentUser,
    setCurrentUser,
    supportUser,
    setSupportUser,
  };
};

interface Props {
  children: JSX.Element;
}

const AuthProvider = ({ children }: Props) => {
  const auth = useAuth();

  return <AuthContextProvider value={auth}>{children}</AuthContextProvider>;
};

export { AuthProvider, useAuthContext };
