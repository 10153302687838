import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";
import { JoinBunchQuery, JoinBunchQueryVariables } from "../types";

export const JOIN_BUNCH: TypedDocumentNode<JoinBunchQuery, JoinBunchQueryVariables> = gql`
  ${BUNCH_DETAILS}
  query joinBunch($bunchId: ID!, $userId: ID!) {
    joinBunch(bunchId: $bunchId, userId: $userId) {
      ...BunchDetails
    }
  }
`;
