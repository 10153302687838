import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { USER_DETAILS } from "../fragments";
import { FetchUserQuery, FetchUserQueryVariables } from "../types";

export const FETCH_USER: TypedDocumentNode<FetchUserQuery, FetchUserQueryVariables> = gql`
  ${USER_DETAILS}
  query fetchUser($token: String, $userId: ID, $email: String, $phone: String) {
    fetchUser(token: $token, userId: $userId, email: $email, phone: $phone) {
      ...UserDetails
    }
  }
`;
