import { captureException } from "@sentry/nextjs";
import { displayToastError } from "../components/shared/toasts";

const env = process.env.NODE_ENV;

export function hexToRGBA(hex, alpha = 1) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function copyToClipboard(text: string) {
  return new Promise<void>((resolve) => {
    var textNode = document.createTextNode(text);
    var textRange;
    var body = document.body as HTMLBodyElement;
    body.appendChild(textNode);
    // @ts-ignore, IE specific
    if (body.createTextRange) {
      // @ts-ignore, IE specific
      textRange = body.createTextRange();
      textRange.moveToElementText(textNode);
      textRange.select();
      document.execCommand("copy");
    } else {
      textRange = document.createRange();
      var selection = window.getSelection;
      textRange.selectNodeContents(textNode);
      selection()?.removeAllRanges();
      selection()?.addRange(textRange);
      document.execCommand("copy");
      selection()?.removeAllRanges();
    }
    textNode.remove();

    resolve();
  });
}

export function getWebURLFromBunchesURL(bunchesUrl: string) {
  if (bunchesUrl.includes("bunches://bunch/")) {
    let id = bunchesUrl.replace("bunches://bunch/", "");
    return `bunch-id:${id}`;
  }
  switch (bunchesUrl) {
    case "bunches://bunches":
      return "/chat";
    case "bunches://profile":
      return "/profile";
    case "bunches://share":
      return "/chat";
    default:
      return "/chat";
  }
}

/**
 * Log a a caught exception to the console, report it to Sentry, and display an error toast.
 *
 * @param err Error from a try catch statement.
 * @param message Message to display as an error toast. Defaults to error.message.
 */
export async function handleCaughtError(err: unknown, message?: string) {
  console.warn(err);

  if (env === "production") {
    captureException(err);
  }

  // @ts-ignore
  const errorMessage: string = message || err.message;

  if (errorMessage) {
    await displayToastError(errorMessage);
  }
}

export function moveElement(array: any[], from: number, to: number) {
  const copy = [...array];
  const valueToMove = copy.splice(from, 1)[0];
  copy.splice(to, 0, valueToMove);
  return copy;
}

export function calcTimestamp(currentDate: Date, messageInsertedAt: string) {
  if (!currentDate) return null;
  const timeFormatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const currentTime = currentDate.valueOf();
  const messageTime = new Date(messageInsertedAt).valueOf();

  let diff = currentTime - messageTime;
  if (diff < 0) diff = 0;
  let unit: Intl.RelativeTimeFormatUnit = "year";
  let formattedDiff = 0;

  //get unit based on time since message inserted
  switch (true) {
    case diff <= 60000:
      unit = "second";
      formattedDiff = Math.floor(diff / 1000);
      break;
    case diff > 60000 && diff <= 3600000:
      unit = "minute";
      formattedDiff = Math.floor(diff / 60000);
      break;
    case diff > 3600000 && diff <= 86400000:
      unit = "hour";
      formattedDiff = Math.floor(diff / 3600000);
      break;
    case diff > 86400000 && diff <= 604800000:
      unit = "day";
      formattedDiff = Math.floor(diff / 86400000);
      break;
    case diff > 604800000 && diff <= 2419200000:
      unit = "week";
      formattedDiff = Math.floor(diff / 604800000);
      break;
    case diff > 2419200000 && diff <= 29030400000:
      unit = "month";
      formattedDiff = Math.floor(diff / 2419200000);
      break;
    default:
      unit = "year";
      formattedDiff = Math.floor(diff / 29030400000);
      break;
  }

  return timeFormatter.format(formattedDiff * -1, unit);
}

export function formatPubKey(key: string) {
  return `${key.slice(0, 4)}...${key.slice(key.length - 4)}`;
}

export function getYoutubeVideoId(url: string) {
  const groups = url.match(/(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/i); //https://regexr.com/3nsop
  return groups ? groups[3] : undefined;
}

export async function validateFile(file: File) {
  const allowedFileTypes = ["jpeg", "png", "jpg"];
  if (!file || !file.name) return false;
  const fileType = file.name.toLocaleLowerCase().split(".").pop() as string;
  if (allowedFileTypes.includes(fileType)) return true;
  await handleCaughtError(new Error(`Cannot upload .${fileType} files`), `Cannot upload .${fileType} files`);
  return false;
}

/**
 * Checks to see if the given email is a valid one.
 *
 * @param email Email to be validated
 */
export function isValidEmail(email: string | null | undefined) {
  if (!email) return false;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function amountStringToInt(amount: string | undefined): number {
  if (!amount) return 0;
  return Math.floor(parseFloat(amount.replaceAll("$", "")) * 100) ?? 0;
}

export function amountIntToString(amount: number): string {
  if (amount <= 0) return "0";
  let cents: string | number = amount % 100;
  if (cents < 10) cents = `0${cents}`;
  let dollars = Math.floor(amount / 100);
  return `${dollars}.${cents}`;
}

// Reformat amount to currency, i.e. 27. -> 27 || 27.1 -> 27.10
export function reformatCurrency(amount: string) {
  if (!amount.includes(".")) return amount;

  const [dollars, cents] = amount.split(".");
  if (!cents || cents === "0" || cents === "00") return dollars;

  return Number(amount).toFixed(2);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function fileToBase64(file: File) {
  if (!file) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function validateDate(date: string) {
  if (date.length < 10) return false;
  if (!/\d{2}-\d{2}-\d{4}/.test(date)) return false;
  if (!Date.parse(date)) return false;
  return true;
}

/**
 * throws Error if date is less than 13 years from today
 * @returns Date formatted as YYYY-MM-DD
 */
export function validateAge(date: string) {
  const ageDate = new Date(date);
  const minAgeDate = new Date(Date.now() - 410240038000); //13 years in milliseconds
  if (ageDate > minAgeDate) throw new Error("You must be at least 13 to use Bunches");
  return ageDate.toISOString().split("T")[0];
}

export function getArcPath(start: number, end: number, innerRadius: number, outerRadius: number) {
  const startAngle = start * Math.PI * 2;
  const endAngle = end * Math.PI * 2;
  const x1 = innerRadius * Math.sin(startAngle);
  const y1 = innerRadius * -Math.cos(startAngle);
  const x2 = outerRadius * Math.sin(startAngle);
  const y2 = outerRadius * -Math.cos(startAngle);
  const x3 = outerRadius * Math.sin(endAngle);
  const y3 = outerRadius * -Math.cos(endAngle);
  const x4 = innerRadius * Math.sin(endAngle);
  const y4 = innerRadius * -Math.cos(endAngle);
  const bigArc = end - start >= 0.5;
  const outerFlags = bigArc ? "1 1 1" : "0 0 1";
  const innerFlags = bigArc ? "1 1 0" : "1 0 0";
  return `M ${x1},${y1} L ${x2},${y2} A ${outerRadius} ${outerRadius} ${outerFlags} ${x3},${y3}
        L ${x4},${y4} A ${innerRadius} ${innerRadius} ${innerFlags} ${x1},${y1} Z`;
}

export function createDateString(date: Date) {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${month < 10 ? "0" + month : month}.${day < 10 ? "0" + day : day}.${year.toString().slice(-2)} `;
}

/**
 * Takes a BunchesUrl and returns the approriate url for usage outside the iOS app
 *
 * @param bunchesUrl A Bunches App url, i.e. `bunches://share_external_link?...`
 * @return url approriatly formatted for web. Defaults to empty string.
 */
export function getConcordUrlFromBunchesUrl(bunchesUrl: string) {
  const [type, params] = bunchesUrl.split("?");
  switch (type) {
    case "bunches://share_external_link":
      return params.split("=")[1];
    default:
      return "";
  }
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

