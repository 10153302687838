import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import {
  PinMessageMutation,
  PinMessageMutationVariables,
  UnpinMessageMutation,
  UnpinMessageMutationVariables,
} from "../types";

export const PIN_MESSAGE: TypedDocumentNode<PinMessageMutation, PinMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation pinMessage($messageId: ID!, $userId: ID!) {
    pinMessage(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;

export const UNPIN_MESSAGE: TypedDocumentNode<UnpinMessageMutation, UnpinMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation unpinMessage($messageId: ID!, $userId: ID!) {
    unpinMessage(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;
