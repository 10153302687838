import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { GetThreadQuery, GetThreadQueryVariables } from "../types";

export const FETCH_THREAD: TypedDocumentNode<GetThreadQuery, GetThreadQueryVariables> = gql`
  ${MESSAGE_DETAILS}
  query getThread($messageId: ID!) {
    fetchThread(messageId: $messageId) {
      ...MessageDetails
    }
  }
`;
