import { Address, GetContractEventsReturnType, parseAbi, zeroAddress } from "viem";
import amountToDisplayString from "./amountToDisplayString";

export const abi = parseAbi([
  "event Transfer(address indexed from, address indexed to, uint256 value)",
  "function balanceOf(address) public view returns (uint256)",
  "function transfer(address to, uint256 value) external returns (bool)",
]);

export const MINT = "mint";
export const BURN = "burn";
export const RECEIVE = "receive";
export const SENT = "sent";

type Logs = GetContractEventsReturnType<typeof abi, "Transfer">;

export type Transaction = {
  address?: Address;
  value?: string;
  type: typeof MINT | typeof BURN | typeof RECEIVE | typeof SENT;
  hash?: string;
};

export default function parseTransferLogs(logs: Logs, userAddress: Address) {
  return logs.map((l) => {
    const value = amountToDisplayString(l.args.value);
    let type: typeof MINT | typeof BURN | typeof RECEIVE | typeof SENT;
    let address;

    if (l.args.from === zeroAddress) {
      type = MINT;
      address = l.address;
    } else if (l.args.to === zeroAddress) {
      type = BURN;
      address = l.address;
    } else if (l.args.to === userAddress) {
      type = RECEIVE;
      address = l.args.from;
    } else {
      type = SENT;
      address = l.args.to;
    }
    return { type, address, value, hash: l.transactionHash };
  });
}
