import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { createGenericContext } from "../../utilities/context";
import { FEATURE_FLAGS } from "../../gql/admin/featureFlags";

type UseFeatureFlags = {
  featureFlags: Record<string, boolean> | null;
  setFeatureFlags: Dispatch<SetStateAction<Record<string, boolean> | null>>;
  featureFlagsLoading: boolean;
};

const FLAGS = [
  // "wallet",
  // "mute_bunch",
  // "rankings",
  // "discover_v2",
  // "high_quality_video_export",
  // "image_disk_cache_allow_clear",
  // "new_friend_center",
  // "profile_v2",
  // "show_external_action_buttons",
  // "show_message_timestamps",
  // "squeek",
  // "test_image_issue",
  // "bunches_wallet_settings",
  // "disable_love",
  // "disable_topics",
  // "interests",
  // "can_dismiss_interests_cta",
  // "show_text_on_pinned_links_media",
  // "onboarding_require_birthday",
  // "highlights",
  "badges",
  "decoration_badges",
];

const [useFeatureFlagsContext, FeatureFlagsContextProvider] = createGenericContext<UseFeatureFlags>();

const useFeatureFlags = (): UseFeatureFlags => {
  const { data, loading: featureFlagsLoading } = useQuery(FEATURE_FLAGS, { fetchPolicy: "no-cache" });

  // Feature Flags Context
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean> | null>(null);

  useEffect(() => {
    if (!data || !data.features || featureFlags) return;

    setFeatureFlags(Object.fromEntries(FLAGS.map((item) => [item, data.features.includes(item)])));
  }, [data, featureFlags, setFeatureFlags]);

  return {
    featureFlags,
    setFeatureFlags,
    featureFlagsLoading,
  };
};

interface Props {
  children: JSX.Element;
}

const FeatureFlagsProvider = ({ children }: Props) => {
  const flags = useFeatureFlags();

  return <FeatureFlagsContextProvider value={flags}>{children}</FeatureFlagsContextProvider>;
};

export { useFeatureFlagsContext, FeatureFlagsProvider };
