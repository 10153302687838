import { Bunch, BunchDetailsUltraLight, User } from "../../../types";
import { BunchDetailsFragment, UserDetailsFragment } from "../../../gql/types";
import { DIRECT_MESSAGE } from "../../../gql/bunch/directMessage";
import { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from "react";
import { FETCH_PARTICIPATION } from "../../../gql/bunch/fetchParticipation";
import { FETCH_SIDEBAR_BUNCHES } from "../../../gql/bunch/fetchBunch";
import { JOIN_BUNCH } from "../../../gql/bunch/joinBunch";
import { LEAVE_BUNCH } from "../../../gql/bunch/leaveBunch";
import { MARK_AS_READ } from "../../../gql/message/markAsRead";
import { createGenericContext } from "../../../utilities/context";
import { handleCaughtError } from "../../../utilities";
import { useAuthContext } from "../AuthProvider";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_BUNCH_MEMBERS, initialState, reducer } from "./state";
import { MUTE_BUNCH } from "../../../gql/bunch/muteBunch";
import { PIN_BUNCH } from "../../../gql/bunch/pinBunch";
import { FETCH_BUNCH_MEMBERS } from "../../../gql/bunch/fetchMembersForBunch";
import { FETCH_SUPPORT_CHATS } from "../../../gql/adminTools/fetchGrowth";
import uniqBy from "lodash.uniqby";

type SupportChat = {
  bunch: BunchDetailsFragment;
};

type UseBunches = {
  bunches: BunchDetailsFragment[];
  setBunches: Dispatch<SetStateAction<BunchDetailsFragment[]>>;
  fetchBunches: () => Promise<void>;
  joinBunch: (bunchId: BunchDetailsFragment["bunchId"]) => Promise<void>;
  muteBunch: (bunchId: BunchDetailsFragment["bunchId"]) => void;
  pinBunch: (bunchId: BunchDetailsFragment["bunchId"]) => void;
  leaveBunch: (bunchId: BunchDetailsFragment["bunchId"]) => Promise<void>;
  markBunchAsRead: (messageId: string) => void;
  isBunchMember: (bunch: BunchDetailsFragment | BunchDetailsUltraLight) => boolean;
  currentBunch: BunchDetailsFragment | null;
  setCurrentBunch: Dispatch<SetStateAction<BunchDetailsFragment | null>>;
  updateBunch: (updated: BunchDetailsFragment) => void;
  activeThreadId: string | null;
  setActiveThreadId: Dispatch<SetStateAction<string | null>>;
  userIsCreator: (user: UserDetailsFragment | null) => boolean;
  startDM: (recipientId: string) => Promise<BunchDetailsFragment | null>;
  checkIfMember: (bunchId: string, userId: string) => Promise<boolean>;
  bunchMembers: UserDetailsFragment[]; // Current bunch members
  supportChats: SupportChat[] | [];
  setSupportChats: Dispatch<SetStateAction<SupportChat[]>>;
  fetchSupportChats: () => Promise<void>;
  currentSupportChat: SupportChat | null;
  setCurrentSupportChat: Dispatch<SetStateAction<SupportChat | null>>;
  /**
   * getBunchMembers is exposed for use when a user is not on a chat page with a currentBunch.
   */
  getBunchMembers: (bunchId: string) => Promise<UserDetailsFragment[]>;
};

const [useBunchesContext, BunchesContextProvider] = createGenericContext<UseBunches>();

const useBunches = (): UseBunches => {
  const { currentUser, supportUser } = useAuthContext();

  // Queries
  const [fetchSupportChatsQuery, { data }] = useLazyQuery(FETCH_SUPPORT_CHATS);
  const [fetchBunchesQuery, { called, loading }] = useLazyQuery(FETCH_SIDEBAR_BUNCHES);
  const [joinBunchQuery] = useLazyQuery(JOIN_BUNCH);
  const [checkIfMemberQuery] = useLazyQuery(FETCH_PARTICIPATION);
  const [fetchBunchMembers] = useLazyQuery(FETCH_BUNCH_MEMBERS);

  // Mutations
  const [leaveBunchQuery] = useMutation(LEAVE_BUNCH);
  const [markAsReadMutation] = useMutation(MARK_AS_READ);
  const [muteBunch] = useMutation(MUTE_BUNCH);
  const [pinBunch] = useMutation(PIN_BUNCH);
  const [startDirectMessage] = useMutation(DIRECT_MESSAGE);

  // State
  const [state, dispatch] = useReducer(reducer, initialState);
  const [supportChats, setSupportChats] = useState<SupportChat[]>([]);
  const [bunches, setUnorderedBunches] = useState<BunchDetailsFragment[]>([]);
  const [currentBunch, setCurrentBunch] = useState<BunchDetailsFragment | null>(null);
  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);
  const [bunchMembers, setBunchMembers] = useState<UserDetailsFragment[]>([]);
  const [currentSupportChat, setCurrentSupportChat] = useState<SupportChat | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setBunches = useCallback((bunchesToOrder) => {
    setUnorderedBunches(
      uniqBy(
        bunchesToOrder.sort((a, b) => {
          // Set based on pin.
          if (a.isPinned === b.isPinned) {
            return 0;
          }
          if (a.isPinned && !b.isPinned) {
            return -1;
          }
          return 1;
        }),
      ),
    );
  }, []);

  const fetchSupportChats = useCallback(async () => {
    if (!currentUser || !supportUser) return;

    try {
      if (currentUser.isAdmin) {
        fetchSupportChatsQuery();

        if (data && data.fetchSupportChats?.length > 0) {
          setSupportChats(data.fetchSupportChats as SupportChat[]);
        }
      }
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [fetchSupportChatsQuery, currentUser, supportUser, data]);

  const fetchBunches = useCallback(async () => {
    if ((called && loading) || !currentUser) return;

    try {
      const { data } = await fetchBunchesQuery({
        variables: { userId: currentUser.userId },
      });

      if (data && data.fetchBunches?.length > 0) {
        setBunches(data.fetchBunches as BunchDetailsFragment[]);
      }
    } catch (err) {
      await handleCaughtError(err);
    }
  }, [called, loading, fetchBunchesQuery, currentUser, setBunches]);

  /**
   * Join a bunch by bunchId.
   */
  const joinBunch = useCallback(
    async (bunchId: string) => {
      if (!currentUser || !bunchId) return;

      try {
        const response = await joinBunchQuery({ variables: { bunchId, userId: currentUser?.userId } });
        const newBunch = response.data?.joinBunch;

        if (newBunch) {
          const updatedBunches: BunchDetailsFragment[] = [newBunch, ...bunches];
          setBunches(updatedBunches);

          // Re-fetch updated bunches
          fetchBunches();
        }
      } catch (err) {
        await handleCaughtError(err);
      }
    },
    [currentUser, joinBunchQuery, bunches, fetchBunches, setBunches],
  );

  /**
   * Leave a bunch by bunchId.
   */
  const leaveBunch = useCallback(
    async (bunchId: string) => {
      if (!currentUser || !bunchId) return;

      try {
        await leaveBunchQuery({ variables: { bunchId, userId: currentUser?.userId } });

        const updatedBunches: BunchDetailsFragment[] = bunches.filter((b) => b.bunchId !== bunchId);
        setBunches(updatedBunches);

        // Re-fetch updated bunches
        fetchBunches();
      } catch (err) {
        await handleCaughtError(err);
      }
    },
    [currentUser, leaveBunchQuery, bunches, fetchBunches, setBunches],
  );

  const markBunchAsRead = useCallback(
    async (messageId: string) => {
      if (!messageId || !currentUser || !currentUser.userId) return;

      try {
        const { data } = await markAsReadMutation({
          variables: {
            messageId,
            userId: currentUser.userId,
          },
        });

        if (!data?.markAsRead) return;
        const markedMessage = data.markAsRead;

        const updatedBunches = bunches.map((b) => {
          if (b.bunchId === markedMessage.bunchId) {
            return {
              ...b,
              latestMessage: markedMessage,
              lastMessageRead: markedMessage,
            };
          }
          return b;
        });

        setBunches(updatedBunches);
      } catch (err) {
        await handleCaughtError(err);
      }
    },
    [markAsReadMutation, currentUser, bunches, setBunches],
  );

  const updateBunch = useCallback(
    (updated: BunchDetailsFragment) => {
      if (!updated) return;

      const updatedBunchList = bunches.map((b: BunchDetailsFragment) => {
        if (b.bunchId === updated.bunchId) {
          return {
            ...b,
            ...updated,
          };
        }

        return b;
      });

      if (currentBunch && currentBunch.bunchId === updated.bunchId) {
        setCurrentBunch(updated);
      }

      setBunches(updatedBunchList);
    },
    [bunches, currentBunch, setBunches],
  );

  const isBunchMember = useCallback(
    (bunch: Bunch | BunchDetailsUltraLight) => {
      if (!bunch) {
        return false;
      }
      return !!bunches.find((b: BunchDetailsUltraLight) => b.bunchId === bunch.bunchId);
    },
    [bunches],
  );

  const userIsCreator = useCallback(
    (user: User | null) => {
      if (!currentBunch || !user) return false;

      return currentBunch.creatorId === user.userId;
    },
    [currentBunch],
  );

  const startDM = useCallback(
    async (recipientId: string) => {
      try {
        const response = await startDirectMessage({
          variables: { userId: currentUser?.userId, recipientIds: [recipientId] },
        });
        const dmBunch: BunchDetailsFragment = response.data?.startDirectMessage;
        const existingDmBunch: BunchDetailsFragment | undefined = bunches.find(
          (bunch: BunchDetailsFragment) => bunch.bunchId === dmBunch.bunchId,
        );

        if (!existingDmBunch) {
          const updatedBunches: BunchDetailsFragment[] = [dmBunch, ...bunches];
          setBunches(updatedBunches);
        }

        return dmBunch;
      } catch (error) {
        await handleCaughtError(error, error?.message || "Unable to message friend. Please reload and try again.");
      }

      return null;
    },
    [bunches, startDirectMessage, currentUser, setBunches],
  );

  const checkIfMember = useCallback(
    async (bunchId: string, userId: string) => {
      try {
        const { data } = await checkIfMemberQuery({ variables: { bunchId, userId } });
        if (data && data.fetchParticipationById.isParticipating) return data.fetchParticipationById.isParticipating;
        return false;
      } catch (err) {
        handleCaughtError(err);
        return false;
      }
    },
    [checkIfMemberQuery],
  );

  const muteOrUnmuteBunch = useCallback(
    async (bunchId: string, isMuted: boolean) => {
      if (!bunchId) return;

      try {
        const { data } = await muteBunch({ variables: { bunchId: bunchId, mute: !isMuted } });

        if (data?.muteBunch) {
          updateBunch(data.muteBunch);
        }

        return;
      } catch (err) {
        handleCaughtError(err);
        return;
      }
    },
    [muteBunch, updateBunch],
  );

  const pinOrUnpinBunch = useCallback(
    async (bunchId: string, isPinned: boolean) => {
      if (!bunchId) return;

      try {
        const { data } = await pinBunch({ variables: { bunchId: bunchId, pin: !isPinned } });

        if (data?.pinBunch) {
          updateBunch(data.pinBunch);
        }

        return;
      } catch (err) {
        handleCaughtError(err);
        return;
      }
    },
    [pinBunch, updateBunch],
  );

  const getBunchMembers = useCallback(
    async (bunchId: string) => {
      if (!bunchId) return [];

      if (state.members[bunchId]) return state.members[bunchId];

      try {
        const { data } = await fetchBunchMembers({ variables: { bunchId } });

        if (data?.fetchMembersForBunch) {
          dispatch({
            type: ADD_BUNCH_MEMBERS,
            payload: { members: data.fetchMembersForBunch as UserDetailsFragment[], bunchId },
          });

          return data.fetchMembersForBunch as UserDetailsFragment[];
        }

        return [];
      } catch (err) {
        handleCaughtError(err);
        return [];
      }
    },
    [fetchBunchMembers, state.members],
  );

  useEffect(() => {
    if (!currentUser || called) return;

    fetchBunches();
  }, [currentUser, fetchBunches, called]);

  // Fetch bunch members when switching current bunch
  useEffect(() => {
    if (currentBunch) {
      getBunchMembers(currentBunch.bunchId);
    }
  }, [currentBunch, getBunchMembers]);

  // Fetch bunch members when switching current bunch
  useEffect(() => {
    if (currentBunch && state.members[currentBunch.bunchId]) {
      setBunchMembers(state.members[currentBunch.bunchId]);
    }
  }, [currentBunch, state.members]);

  return {
    bunches,
    setBunches,
    joinBunch,
    muteOrUnmuteBunch,
    pinOrUnpinBunch,
    leaveBunch,
    markBunchAsRead,
    isBunchMember,
    fetchBunches,
    currentBunch,
    setCurrentBunch,
    updateBunch,
    activeThreadId,
    setActiveThreadId,
    userIsCreator,
    startDM,
    checkIfMember,
    bunchMembers,
    getBunchMembers,
    supportChats,
    setSupportChats,
    fetchSupportChats,
    currentSupportChat,
    setCurrentSupportChat,
  };
};

interface Props {
  children: JSX.Element;
}

const BunchesProvider = ({ children }: Props) => {
  const bunches = useBunches();

  return <BunchesContextProvider value={bunches}>{children}</BunchesContextProvider>;
};

export { useBunchesContext, BunchesProvider };
