import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import {
  FetchBunchSupportMetricsQuery,
  FetchBunchSupportMetricsQueryVariables,
  FetchBunchTimeframeGrowthQuery,
  FetchBunchTimeframeGrowthQueryVariables,
  FetchCreatorTimeframeGrowthQuery,
  FetchCreatorTimeframeGrowthQueryVariables,
  FetchDeviceSupportMetricsQuery,
  FetchDeviceSupportMetricsQueryVariables,
  FetchHomeBunchBenchmarksQuery,
  FetchHomeBunchBenchmarksQueryVariables,
  FetchMessageSupportMetricsQuery,
  FetchMessageSupportMetricsQueryVariables,
  FetchReferralSupportMetricsQuery,
  FetchReferralSupportMetricsQueryVariables,
  FetchScoutSupportMetricsQuery,
  FetchScoutSupportMetricsQueryVariables,
  FetchUserSupportMetricsQuery,
  FetchUserSupportMetricsQueryVariables,
} from "../types";

export const FETCH_USER_SUPPPORT_METRICS: TypedDocumentNode<
  FetchUserSupportMetricsQuery,
  FetchUserSupportMetricsQueryVariables
> = gql`
  query fetchUserSupportMetrics($timeframe: String!) {
    fetchUserSupportMetrics(timeframe: $timeframe) {
      userCount
    }
  }
`;

export const FETCH_BUNCH_SUPPPORT_METRICS: TypedDocumentNode<
  FetchBunchSupportMetricsQuery,
  FetchBunchSupportMetricsQueryVariables
> = gql`
  query fetchBunchSupportMetrics($timeframe: String!) {
    fetchBunchSupportMetrics(timeframe: $timeframe) {
      bunchCount
    }
  }
`;

export const FETCH_MESSAGE_SUPPPORT_METRICS: TypedDocumentNode<
  FetchMessageSupportMetricsQuery,
  FetchMessageSupportMetricsQueryVariables
> = gql`
  query fetchMessageSupportMetrics($timeframe: String!) {
    fetchMessageSupportMetrics(timeframe: $timeframe) {
      messageCount
      senderCount
      reactionCount
    }
  }
`;

export const FETCH_DEVICE_SUPPPORT_METRICS: TypedDocumentNode<
  FetchDeviceSupportMetricsQuery,
  FetchDeviceSupportMetricsQueryVariables
> = gql`
  query fetchDeviceSupportMetrics($timeframe: String!) {
    fetchDeviceSupportMetrics(timeframe: $timeframe) {
      webDeviceCount
      iosDeviceCount
      androidDeviceCount
    }
  }
`;

export const FETCH_SCOUT_SUPPPORT_METRICS: TypedDocumentNode<
  FetchScoutSupportMetricsQuery,
  FetchScoutSupportMetricsQueryVariables
> = gql`
  query fetchScoutSupportMetrics($timeframe: String!) {
    fetchScoutSupportMetrics(timeframe: $timeframe) {
      scoutCount
    }
  }
`;

export const FETCH_REFERRAL_SUPPPORT_METRICS: TypedDocumentNode<
  FetchReferralSupportMetricsQuery,
  FetchReferralSupportMetricsQueryVariables
> = gql`
  query fetchReferralSupportMetrics($timeframe: String!) {
    fetchReferralSupportMetrics(timeframe: $timeframe) {
      referralCount
      referrerCount
      bunchCount
    }
  }
`;

export const FETCH_SUPPORT_CREATORS = gql`
  query fetchSupportCreators {
    fetchSupportCreators {
      bunchCategories
      bunchCount
      creatorId
      creatorUsername
      email
      insertedAt
      isScoutUser
      memberCount
      messageCount
      phone
      profilePictureUrl
    }
  }
`;

export const FETCH_SUPPORT_BUNCHES = gql`
  query fetchSupportBunches {
    fetchSupportBunches {
      bunchId
      bunchName
      bunchCategory
      discoverBackgroundUrl
      createdAt
      isScoutBunch
      lastMessageAt
      memberCount
      messageCount
      senderCount
      bunchSlug
      creatorId
      creatorUsername
    }
  }
`;

export const FETCH_CATEGORY_BENCHMARKS = gql`
  query fetchCategoryBenchmarks {
    fetchCategoryBenchmarks {
      category
      memberCount
      creatorCount
      bunchCount
      messageCount
      senderCount
      reactionCount
      reacterCount
    }
  }
`;

export const FETCH_HOME_BUNCH_BENCHMARKS: TypedDocumentNode<
  FetchHomeBunchBenchmarksQuery,
  FetchHomeBunchBenchmarksQueryVariables
> = gql`
  query fetchHomeBunchBenchmarks($bunchType: String!) {
    fetchHomeBunchBenchmarks(bunchType: $bunchType) {
      bunchId
      bunchName
      bunchCategory
      bunchDiscoverPhoto
      memberCount
      messageCount
      reacterCount
      reactionCount
      senderCount
    }
  }
`;

export const FETCH_USER_TIMELINE_GROWTH = gql`
  query fetchUserTimelineGrowth {
    fetchUserTimelineGrowth {
      joinedAt
      totalUserCount
      domesticUserCount
      internationalUserCount
    }
  }
`;

export const FETCH_MESSAGE_TIMELINE_GROWTH = gql`
  query fetchMessageTimelineGrowth {
    fetchMessageTimelineGrowth {
      joinedAt
      totalMessageCount
      totalSenderCount
      domesticMessageCount
      domesticSenderCount
      internationalMessageCount
      internationalSenderCount
    }
  }
`;

export const FETCH_AUDIT_USERS = gql`
  query fetchAuditUsers {
    fetchAuditUsers {
      userId
      username
      phone
      email
      profilePictureUrl
      insertedAt
    }
  }
`;

export const FETCH_AUDIT_BUNCHES = gql`
  query fetchAuditBunches {
    fetchAuditBunches {
      bunchId
      creatorId
      creatorUsername
      creatorProfilePhoto
      bunchName
      category
      slug
      bunchDiscoverPhoto
      insertedAt
    }
  }
`;

export const FETCH_SUPPORT_CHATS = gql`
  query fetchSupportChats {
    fetchSupportChats {
      bunch {
        bunchId
        category
        chargeAmount
        chargeFrequency
        contractAddress
        creatorId
        description
        discoverBackgroundUrl
        discoverCategoryBgColor
        discoverCategoryTextColor
        discoverDescription
        discoverDescriptionBgColor
        discoverDescriptionTextColor
        discoverDetailBgColor
        discoverDetailTextColor
        discoverEndDate
        discoverIconUrl
        discoverPlaceholderBackgroundUrl
        discoverStartDate
        discoverTitle
        discoverTitleTextColor
        expirationDate
        formattedPrice
        insertedAt
        isAnonymous
        isDiscoverActive
        isEphemeral
        isFree
        isMuted
        isPreview
        isPublic
        isRead
        isTokenLocked
        isTwitter
        isYoutube
        lastMessageAt
        link
        memberCount
        memberLimit
        name
        needsRenewal
        parentBunchId
        productId
        shareCode
        slug
        sportsId
        tokenThreshold
        twitterUsername
        updatedAt
        waitlistCount
        youtubeChannelId
        youtubeUsername
      }
      status {
        bunchId
        initialUserId
        initialUsername
        initialProfilePictureUrl
        initialUserDevices
        joinedAt
        lastMessageAt
        lastUserId
        lastUsername
      }
    }
  }
`;

export const FETCH_CREATOR_TIMEFRAME_GROWTH: TypedDocumentNode<
  FetchCreatorTimeframeGrowthQuery,
  FetchCreatorTimeframeGrowthQueryVariables
> = gql`
  query fetchCreatorTimeframeGrowth(
    $growthType: String!
    $regional: Boolean!
    $starting: String!
    $ending: String!
    $users: [String]
    $scoutBunch: String
  ) {
    fetchCreatorTimeframeGrowth(
      growthType: $growthType
      regional: $regional
      starting: $starting
      ending: $ending
      users: $users
      scoutBunch: $scoutBunch
    ) {
      ... on CreatorReferralsTimeframeGrowth {
        userId
        username
        phone
        email
        profilePictureUrl
        creatorJoinedAt
        creatorDevices
        bunchCategories
        country
        countryCode
        referralCount
        bunchCount
      }
      ... on CreatorMembersTimeframeGrowth {
        userId
        username
        phone
        email
        profilePictureUrl
        creatorJoinedAt
        creatorDevices
        country
        countryCode
        memberCount
        senderCount
        messageCount
        bunchCount
      }
      ... on RegionalCreatorReferralsTimeframeGrowth {
        userId
        username
        phone
        email
        profilePictureUrl
        creatorJoinedAt
        creatorDevices
        bunchCategories
        country
        countryCode
        usaReferralCount
        europeReferralCount
        otherReferralCount
      }
      ... on RegionalCreatorMembersTimeframeGrowth {
        userId
        username
        phone
        email
        profilePictureUrl
        creatorJoinedAt
        creatorDevices
        country
        countryCode
        usaMemberCount
        europeMemberCount
        otherMemberCount
      }
    }
  }
`;

export const FETCH_BUNCH_TIMEFRAME_GROWTH: TypedDocumentNode<
  FetchBunchTimeframeGrowthQuery,
  FetchBunchTimeframeGrowthQueryVariables
> = gql`
  query fetchBunchTimeframeGrowth(
    $growthType: String!
    $regional: Boolean!
    $starting: String!
    $ending: String!
    $bunches: [String]
  ) {
    fetchBunchTimeframeGrowth(
      growthType: $growthType
      regional: $regional
      starting: $starting
      ending: $ending
      bunches: $bunches
    ) {
      ... on BunchReferralsTimeframeGrowth {
        bunchId
        bunchName
        bunchSlug
        bunchCategory
        bunchBackgroundPhoto
        lastMessageAt
        createdAt
        creatorId
        creatorUsername
        creatorPhone
        creatorDevices
        country
        countryCode
        referralCount
        inviterCount
      }
      ... on BunchMembersTimeframeGrowth {
        bunchId
        bunchName
        bunchSlug
        bunchCategory
        bunchBackgroundPhoto
        lastMessageAt
        createdAt
        creatorId
        creatorUsername
        creatorPhone
        creatorDevices
        country
        countryCode
        memberCount
        senderCount
        messageCount
      }
      ... on RegionalBunchReferralsTimeframeGrowth {
        bunchId
        bunchName
        bunchSlug
        bunchCategory
        bunchBackgroundPhoto
        lastMessageAt
        createdAt
        creatorId
        creatorUsername
        creatorPhone
        creatorDevices
        country
        countryCode
        usaReferralCount
        europeReferralCount
        otherReferralCount
      }
      ... on RegionalBunchMembersTimeframeGrowth {
        bunchId
        bunchName
        bunchSlug
        bunchCategory
        bunchBackgroundPhoto
        lastMessageAt
        createdAt
        creatorId
        creatorUsername
        creatorPhone
        creatorDevices
        country
        countryCode
        usaMemberCount
        europeMemberCount
        otherMemberCount
      }
    }
  }
`;
