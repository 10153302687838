import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS, BUNCH_DETAILS_ULTRA_LIGHT, LIMITED_BUNCH_DETAILS } from "../fragments";
import {
  FetchBunchQuery,
  FetchBunchQueryVariables,
  FetchSidebarBunchesQuery,
  FetchSidebarBunchesQueryVariables,
  FetchPublicBunchesQuery,
  FetchPublicBunchesQueryVariables,
  FetchPrivateBunchesQuery,
} from "../types";

export const FETCH_BUNCH: TypedDocumentNode<FetchBunchQuery, FetchBunchQueryVariables> = gql`
  ${BUNCH_DETAILS}
  query fetchBunch($bunchId: ID!) {
    fetchBunch(bunchId: $bunchId) {
      ...BunchDetails
    }
  }
`;

// export const FETCH_BUNCHES = gql`
//   ${BUNCH_DETAILS}
//   query fetchBunches($userId: ID!) {
//     fetchBunches(userId: $userId) {
//       ...BunchDetails
//     }
//   }
// `;

export const FETCH_SIDEBAR_BUNCHES: TypedDocumentNode<
  FetchSidebarBunchesQuery,
  FetchSidebarBunchesQueryVariables
> = gql`
  ${LIMITED_BUNCH_DETAILS}
  query fetchSidebarBunches($userId: ID!) {
    fetchBunches(userId: $userId) {
      ...LimitedBunchDetails
    }
  }
`;

export const FETCH_PRIVATE_BUNCHES: TypedDocumentNode<FetchPrivateBunchesQuery, FetchPublicBunchesQueryVariables> = gql`
  ${BUNCH_DETAILS}
  query fetchPrivateBunches($userId: ID!) {
    fetchPrivateBunches(userId: $userId) {
      ...BunchDetails
    }
  }
`;

export const FETCH_PUBLIC_BUNCHES: TypedDocumentNode<FetchPublicBunchesQuery, FetchPublicBunchesQueryVariables> = gql`
  ${BUNCH_DETAILS_ULTRA_LIGHT}
  query fetchPublicBunches($userId: ID!) {
    fetchPublicBunches(userId: $userId) {
      ...BunchDetailsUltraLight
    }
  }
`;

// export const FETCH_BUNCH_BY_SLUG = gql`
//   ${BUNCH_DETAILS}
//   query fetchBunchBySlug($slug: String!) {
//     fetchBunchBySlug(slug: $slug) {
//       ...BunchDetails
//     }
//   }
// `;
