import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";

export const DIRECT_MESSAGE = gql`
  ${BUNCH_DETAILS}
  mutation startDirectMessage($userId: ID!, $recipientIds: [ID], $term: String, $messageText: String) {
    startDirectMessage(userId: $userId, recipientIds: $recipientIds, term: $term, messageText: $messageText) {
      ...BunchDetails
    }
  }
`;

export const DIRECT_MESSAGE_BULK = gql`
  mutation startDirectMessageBulk($userId: ID!, $recipientIds: [ID]!, $messageText: String) {
    startDirectMessageBulk(userId: $userId, recipientIds: $recipientIds, messageText: $messageText)
  }
`;
