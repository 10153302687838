import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { PARTICIPATION_OBJECT_DETAILS } from "../fragments";
import { FetchParticipationByIdQuery, FetchParticipationByIdQueryVariables } from "../types";

export const FETCH_PARTICIPATION: TypedDocumentNode<
  FetchParticipationByIdQuery,
  FetchParticipationByIdQueryVariables
> = gql`
  ${PARTICIPATION_OBJECT_DETAILS}
  query fetchParticipationById($bunchId: ID!, $userId: ID!) {
    fetchParticipationById(bunchId: $bunchId, userId: $userId) {
      ...ParticipationObjectDetails
    }
  }
`;
