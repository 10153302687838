import { UserDetailsFragment } from "../../../gql/types";

// Constants
export const ADD_BUNCH_MEMBERS = "ADD_BUNCH_MEMBERS";
export const PIN_BUNCH = "PIN_BUNCH";

// Action types
type AddBunchMembers = { type: typeof ADD_BUNCH_MEMBERS; payload: { members: UserDetailsFragment[]; bunchId: string } };
type PinBunch = {type: typeof PIN_BUNCH; payload: {bunchId: string}};
type BunchActions = AddBunchMembers | PinBunch;

// State types
export type MemberStateHash = {
  [id: string]: UserDetailsFragment[]; // keyed by bunchId
};

interface BunchState {
  members: MemberStateHash;
}

// Initial state
export const initialState = {
  members: {}
};

// Reducer
export const reducer = (state: BunchState = initialState, action: BunchActions) => {
  switch (action.type) {
    case ADD_BUNCH_MEMBERS:
      const { members, bunchId } = action.payload;
      if (!members) return state;

      return {
        ...state,
        members: {
          ...state.members,
          [bunchId]: members,
        },
      };

    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};
