import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import {
  ModerateMessageMutation,
  ModerateMessageMutationVariables,
  UnmoderateMessageMutation,
  UnmoderateMessageMutationVariables,
} from "../types";

export const MODERATE_MESSAGE: TypedDocumentNode<ModerateMessageMutation, ModerateMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation moderateMessage($messageId: ID!, $userId: ID!) {
    moderateMessage(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;

export const UNMODERATE_MESSAGE: TypedDocumentNode<UnmoderateMessageMutation, UnmoderateMessageMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation unmoderateMessage($messageId: ID!, $userId: ID!) {
    unmoderateMessage(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;
