import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { RGBColor } from "react-color";
import {
  PollDetailsFragmentFragment,
  RankingDetailsFragment,
  ScoreboardItem,
  SharedMessageFragment,
} from "../../gql/types";
import { createGenericContext } from "../../utilities/context";

export const BOOST = "boost";
export const THREAD = "thread";
export const HIGHLIGHTS = "highlights";
export const BUNCH = "bunch";
export const SHARE_MESSAGE_DETAILS = "share_message_details";
export const SHARE_MESSAGE_DETAILS_MOBILE = "share_message_details_mobile";
export const PROFILE = "profile";
export const METRICS = "metrics";
export const REWARDS = "rewards";
export const FRIEND_REQUESTS = "friend_requests";
export const COMPOSER = "composer";
export const POLL = "poll";
export const POLL_DETAILS = "poll_details";
export const RANKING = "ranking";
export const RANKING_DETAILS = "ranking_details";
export const GAME = "game";
export const BALLS = "balls";

export type PanelTypes =
  | typeof BOOST
  | typeof THREAD
  | typeof HIGHLIGHTS
  | typeof BUNCH
  | typeof SHARE_MESSAGE_DETAILS
  | typeof SHARE_MESSAGE_DETAILS_MOBILE
  | typeof PROFILE
  | typeof METRICS
  | typeof REWARDS
  | typeof FRIEND_REQUESTS
  | typeof COMPOSER
  | typeof POLL
  | typeof POLL_DETAILS
  | typeof RANKING
  | typeof RANKING_DETAILS
  | typeof BALLS
  | typeof GAME;

export type ShareMessageData = {
  shareMessageData: SharedMessageFragment;
  color: RGBColor;
  setColor: Dispatch<SetStateAction<RGBColor>>;
  background: SharedMessageFragment["backgrounds"][number] | undefined;
  setBackground: Dispatch<SetStateAction<SharedMessageFragment["backgrounds"][number] | undefined>>;
};

type DisplayPanelParameters =
| {
      panel: typeof BOOST;
      data: string;
    }
  | {
      panel: typeof BUNCH;
      data: string;
    }
  | {
      panel: typeof PROFILE;
      data: string;
    }
  | {
      panel: typeof GAME;
      data: ScoreboardItem;
    }
  | {
      panel: typeof THREAD;
    }
  | {
      panel: typeof HIGHLIGHTS;
    }
  | {
      panel: typeof REWARDS;
    }
  | {
      panel: typeof FRIEND_REQUESTS;
    }
  | {
      panel: typeof COMPOSER;
    }
  | {
      panel: typeof BALLS;
    }
  | {
      panel: typeof POLL;
      data: string;
    }
  | {
      panel: typeof POLL_DETAILS;
      data: PollDetailsFragmentFragment;
    }
  | {
      panel: typeof RANKING;
      data: string;
    }
  | {
      panel: typeof RANKING_DETAILS;
      data: RankingDetailsFragment;
    }
  | {
      panel: typeof SHARE_MESSAGE_DETAILS_MOBILE;
      data: SharedMessageFragment;
    }
  | {
      panel: typeof SHARE_MESSAGE_DETAILS;
      data: SharedMessageFragment;
    };

type UseRightSidebar = {
  togglePanel: <TPanel extends DisplayPanelParameters["panel"]>(
    ...args: Extract<DisplayPanelParameters, { panel: TPanel }> extends { data: infer TData }
      ? [panel: TPanel, data: TData]
      : [panel: TPanel]
  ) => void;
  displayPanel: <TPanel extends DisplayPanelParameters["panel"]>(
    ...args: Extract<DisplayPanelParameters, { panel: TPanel }> extends { data: infer TData }
      ? [panel: TPanel, data: TData]
      : [panel: TPanel]
  ) => void;
  closePanel: () => void;
  shouldRender: boolean;
  data: any;
  panel: PanelTypes | null;
};

const [useRightSidebarContext, RightSidebarContextProvider] = createGenericContext<UseRightSidebar>();

const useRightSidebar = (): UseRightSidebar => {
  const [shouldRender, setShouldRender] = useState(false);
  const [panel, setPanel] = useState<PanelTypes | null>(null);
  const [data, setData] = useState<any>(null);
  const router = useRouter();

  const togglePanel = (panelType: PanelTypes, data?: any) => {
    if (panelType === panel) { closePanel(); }
    if (panelType !== panel) { displayPanel(panelType, data); }
  };

  const displayPanel = (panel: PanelTypes, data?: any) => {
    setPanel(panel);
    if (data) setData(data);
    setShouldRender(true);
  };

  const closePanel = useCallback(() => {
    setShouldRender(false);
    setData(null);
    setPanel(null);
  }, []);

  useEffect(() => {
    if (!router.asPath) return;
    closePanel();
  }, [router.asPath, closePanel]);

  return { togglePanel, displayPanel, closePanel, shouldRender, data, panel };
};

interface Props {
  children: JSX.Element;
}

const RightSidebarProvider = ({ children }: Props) => {
  const rightSidebar = useRightSidebar();

  return <RightSidebarContextProvider value={rightSidebar}>{children}</RightSidebarContextProvider>;
};

export { useRightSidebarContext, RightSidebarProvider };
