import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MarkNotificationsSeenMutation, MarkNotificationsSeenMutationVariables } from "../types";

export const MARK_NOTIFICATIONS_SEEN: TypedDocumentNode<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
> = gql`
  mutation markNotificationsSeen($notificationIds: [ID]!) {
    markNotificationsSeen(notifications: $notificationIds) {
      notificationId
      seen
    }
  }
`;
