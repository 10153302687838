import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";
import {
  MuteBunchMutation,
  MuteBunchMutationVariables
} from "../types";

export const MUTE_BUNCH: TypedDocumentNode<MuteBunchMutation, MuteBunchMutationVariables> = gql`
  ${BUNCH_DETAILS}
  mutation muteBunch($bunchId: ID!, $mute: Boolean!) {
    muteBunch(bunchId: $bunchId, mute: $mute) {
      ...BunchDetails
    }
  }
`;
