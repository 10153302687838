import { AnalyticsProvider } from "./AnalyticsProvider";
import { AuthProvider } from "./AuthProvider";
import { BunchDetailsProvider } from "./BunchDetailsProvider";
import { BunchOwnerProvider } from "./BunchOwnerProvider";
import { BunchesProvider } from "./BunchesProvider";
import { FeatureFlagsProvider } from "./FeatureFlagProvider";
import { OnboardingProvider } from "./OnboardingProvider";
import { PhoenixProvider } from "./PhoenixProvider";

import { RightSidebarProvider } from "./RightSidebar";
import { UserProvider } from "./UserProvider";
import { NotificationProvider } from "./NotificationProvider";
import { MessagesProvider } from "./MessagesProvider";
import ChainProvider from "./ChainProvider";
import { BallsProvider } from "./BallsProvider";
import { TransactionsProvider } from "./TransactionProvider";
import { DeepLinkProvider } from "./DeepLinkProvider";

export default function StateProvider({ children }: { children: JSX.Element }) {
  return (
    <PhoenixProvider>
      <ChainProvider>
        <AuthProvider>
          <UserProvider>
            <BallsProvider>
              <TransactionsProvider>
                <DeepLinkProvider>
                  <AnalyticsProvider>
                    <BunchesProvider>
                      <MessagesProvider>
                        <NotificationProvider>
                          <BunchOwnerProvider>
                            <BunchDetailsProvider>
                              <FeatureFlagsProvider>
                                <OnboardingProvider>
                                  <RightSidebarProvider>{children}</RightSidebarProvider>
                                </OnboardingProvider>
                              </FeatureFlagsProvider>
                            </BunchDetailsProvider>
                          </BunchOwnerProvider>
                        </NotificationProvider>
                      </MessagesProvider>
                    </BunchesProvider>
                  </AnalyticsProvider>
                </DeepLinkProvider>
              </TransactionsProvider>
            </BallsProvider>
          </UserProvider>
        </AuthProvider>
      </ChainProvider>
    </PhoenixProvider>
  );
}
