import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS } from "../fragments";
import { LeaveBunchMutation, LeaveBunchMutationVariables } from "../types";

export const LEAVE_BUNCH: TypedDocumentNode<LeaveBunchMutation, LeaveBunchMutationVariables> = gql`
  ${BUNCH_DETAILS}
  mutation LeaveBunch($bunchId: ID!, $userId: ID!) {
    leaveBunch(bunchId: $bunchId, userId: $userId) {
      ...BunchDetails
    }
  }
`;
