import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import {
  GetLatestMessagesQuery,
  GetLatestMessagesQueryVariables,
  GetOlderMessagesQuery,
  GetOlderMessagesQueryVariables,
} from "../types";

export const GET_LATEST_MESSAGES: TypedDocumentNode<GetLatestMessagesQuery, GetLatestMessagesQueryVariables> = gql`
  ${MESSAGE_DETAILS}
  query getLatestMessages($bunchId: ID!, $limit: Int!) {
    getMessages(bunchId: $bunchId, limit: $limit) {
      messages {
        ...MessageDetails
      }
    }
  }
`;

export const GET_OLDER_MESSAGES: TypedDocumentNode<GetOlderMessagesQuery, GetOlderMessagesQueryVariables> = gql`
  ${MESSAGE_DETAILS}
  query getOlderMessages($bunchId: ID!, $oldestMessageId: ID, $include: Boolean!, $limit: Int!) {
    getMessages(bunchId: $bunchId, oldestMessageId: $oldestMessageId, include: $include, limit: $limit) {
      messages {
        ...MessageDetails
      }
    }
  }
`;
