import { MessageDetailsFragment } from "../../../gql/types";

export type Message = MessageDetailsFragment;

// Constants
export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const ADD_THREAD = "ADD_THREAD";
export const ADD_REACTION = "ADD_REACTION";
export const REMOVE_REACTION = "REMOVE_REACTION";
export const SET_HIGHLIGHTS = "SET_HIGHLIGHTS";
export const ADD_HIGHLIGHT = "ADD_HIGHLIGHT";
export const REMOVE_HIGHLIGHT = "REMOVE_HIGHLIGHT";
export const UPDATE_COMPOSER_TEXT = "UPDATE_COMPOSER_TEXT";

// Action types
type AddMessages = { type: typeof ADD_MESSAGES; payload: { messages: Message[] } };
type AddMessage = { type: typeof ADD_MESSAGE; payload: { message: Message } };
type DeleteMessage = { type: typeof DELETE_MESSAGE; payload: { message: Message } };
type EditMessage = { type: typeof EDIT_MESSAGE; payload: { message: Message } };
type ClearMessages = { type: typeof CLEAR_MESSAGES; payload: { bunchId: string } };
type AddThread = { type: typeof ADD_THREAD; payload: { thread: Message[] } };

// Highlight action types
type SetHighlights = { type: typeof SET_HIGHLIGHTS; payload: { highlights: Message[] } };
type AddHighlight = { type: typeof ADD_HIGHLIGHT; payload: { highlight: Message } };
type RemoveHighlight = { type: typeof REMOVE_HIGHLIGHT; payload: { highlight: Message } };

// Composer action types
type UpdateComposerText = { type: typeof UPDATE_COMPOSER_TEXT; payload: { composerText: string } };

type MessageActions =
  | AddMessages
  | AddMessage
  | DeleteMessage
  | EditMessage
  | AddThread
  | ClearMessages
  | SetHighlights
  | AddHighlight
  | RemoveHighlight
  | UpdateComposerText;

// State types
export type MessageStateHash = {
  [id: string]: Message[]; // keyed by bunchId for normal messages, and parentMessageId for threads
};

interface MessagesState {
  messages: MessageStateHash;
  threads: MessageStateHash;
  highlights: MessageStateHash;
  composerText: string | null;
}

// Initial state
export const initialState = { messages: {}, threads: {}, highlights: {}, composerText: null };

// Reducer
export const reducer = (state: MessagesState = initialState, action: MessageActions) => {
  switch (action.type) {
    case ADD_MESSAGES:
      const { messages } = action.payload;
      if (!messages || !messages[0].bunchId) return state;

      const messagesState = state.messages[messages[0].bunchId] || [];
      const addedState = [...messagesState, ...messages];

      return {
        ...state,
        messages: {
          ...state.messages,
          [messages[0].bunchId]: addedState,
        },
      };

    case ADD_MESSAGE:
      const { message } = action.payload;
      if (!message.bunchId) return state;

      let isThread = !!message.parentMessageId;

      if (isThread) {
        return {
          ...state,
          threads: {
            ...state.threads,
            [message.parentMessageId]: [message, ...state.threads[message.parentMessageId]],
          },
        };
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [message.bunchId]: [message, ...state.messages[message.bunchId]],
        },
      };

    case DELETE_MESSAGE:
      let deletingThreadMsg = !!action.payload.message.parentMessageId;

      if (deletingThreadMsg) {
        const updatedForDeleted = [...state.threads[action.payload.message.parentMessageId]].filter((message) => {
          return (
            (message.messageId && message.messageId !== action.payload.message.messageId) ||
            (message.clientMessageId && message.clientMessageId !== action.payload.message.clientMessageId)
          );
        });

        return {
          ...state,
          threads: {
            ...state.threads,
            [action.payload.message.parentMessageId]: updatedForDeleted,
          },
        };
      }

      const updatedForDeleted = [...state.messages[action.payload.message.bunchId]].filter((message) => {
        return (
          (message.messageId && message.messageId !== action.payload.message.messageId) ||
          (message.clientMessageId && message.clientMessageId !== action.payload.message.clientMessageId)
        );
      });

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.message.bunchId]: updatedForDeleted,
        },
      };

    case EDIT_MESSAGE:
      let editingThread = !!action.payload.message.parentMessageId;

      if (editingThread) {
        const updatedForEdited = [...state.threads[action.payload.message.parentMessageId]].map((message) => {
          if (
            (message.messageId && message.messageId === action.payload.message.messageId) ||
            (message.clientMessageId && message.clientMessageId === action.payload.message.clientMessageId)
          ) {
            return {
              ...message,
              ...action.payload.message,
              reactions: action.payload.message.reactions,
            };
          }

          return message;
        });

        return {
          ...state,
          threads: {
            ...state.threads,
            [action.payload.message.parentMessageId]: updatedForEdited,
          },
        };
      }

      const updatedForEdited = [...state.messages[action.payload.message.bunchId]].map((message) => {
        if (
          (message.messageId && message.messageId === action.payload.message.messageId) ||
          (message.clientMessageId && message.clientMessageId === action.payload.message.clientMessageId)
        ) {
          return {
            ...message,
            ...action.payload.message,
            reactions: action.payload.message.reactions,
          };
        }

        return message;
      });

      let updatedEditState = {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.message.bunchId]: updatedForEdited,
        },
      };

      // Check for messages that are parents to threads (not thread replies).
      const isThreadParent = state.threads[action.payload.message.messageId];

      if (isThreadParent) {
        const updatedForEditedParent = [...state.threads[action.payload.message.messageId]].map((message) => {
          if (
            (message.messageId && message.messageId === action.payload.message.messageId) ||
            (message.clientMessageId && message.clientMessageId === action.payload.message.clientMessageId)
          ) {
            return {
              ...message,
              ...action.payload.message,
              reactions: action.payload.message.reactions,
            };
          }

          return message;
        });

        updatedEditState = {
          ...updatedEditState,
          threads: {
            ...state.threads,
            [action.payload.message.messageId]: updatedForEditedParent,
          },
        };
      }

      return updatedEditState;
    case ADD_THREAD:
      const { thread } = action.payload;
      if (!thread) return state;

      if (!thread[0].parentMessageId && thread.length === 1) {
        return {
          ...state,
          threads: {
            ...state.threads,
            [thread[0].messageId]: thread,
          },
        };
      }

      const threadsState = state.messages[thread[0].parentMessageId] || [];

      return {
        ...state,
        threads: {
          ...state.threads,
          [thread[0].parentMessageId]: [...threadsState, ...thread],
        },
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.bunchId]: [],
        },
      };

    case SET_HIGHLIGHTS:
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.highlights[0].bunchId]: action.payload.highlights,
        },
      };

    case ADD_HIGHLIGHT:
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.highlight.bunchId]: [
            action.payload.highlight,
            ...(state.highlights[action.payload.highlight.bunchId] || []),
          ],
        },
      };

    case REMOVE_HIGHLIGHT:
      const highlightsForRemoval = [...state.highlights[action.payload.highlight.bunchId]].filter((h) => {
        return h.messageId !== action.payload.highlight.messageId;
      });

      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.highlight.bunchId]: highlightsForRemoval,
        },
      };

    case UPDATE_COMPOSER_TEXT:
      return {
        ...state,
        composerText: action.payload.composerText,
      };

    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};
