import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { MESSAGE_DETAILS } from "../fragments";
import { MarkAsReadMutation, MarkAsReadMutationVariables } from "../types";

export const MARK_AS_READ: TypedDocumentNode<MarkAsReadMutation, MarkAsReadMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation markAsRead($messageId: ID!, $userId: ID!) {
    markAsRead(messageId: $messageId, userId: $userId) {
      ...MessageDetails
    }
  }
`;
